import { Component, Input } from '@angular/core';
import { IDynamicComponent } from '../../../../../core/interfaces/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

@Component({
  selector: 'rh-four-images-right-template',
  templateUrl: './four-images-right-template.component.html',
  styleUrls: ['./four-images-right-template.component.scss'],
})
export class FourImagesRightTemplateComponent
  extends TemplateBaseComponent
  implements IDynamicComponent
{
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T12.svg';

  public static override templateId = 12;

  @Input() public title = '';
  @Input() public html = '';
  @Input() public urlImageTopLeft = '';
  @Input() public urlVideoTopLeft = '';
  @Input() public urlImageTopRight = '';
  @Input() public urlVideoTopRight = '';
  @Input() public urlImageBottomLeft = '';
  @Input() public urlVideoBottomLeft = '';
  @Input() public urlImageBottomRight = '';
  @Input() public urlVideoBottomRight = '';
  @Input() public footnoteTopLeft = '';
  @Input() public footnoteTopRight = '';
  @Input() public footnoteBottomLeft = '';
  @Input() public footnoteBottomRight = '';

  public activatedUrlVideoTopLeft = '';
  public activatedUrlVideoTopRight = '';
  public activatedUrlVideoBottomLeft = '';
  public activatedUrlVideoBottomRight = '';

  private htmlTemp = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_TOP_LEFT) {
        this.urlImageTopLeft = asset.content;
      }
      if (
        asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO_TOP_LEFT
      ) {
        this.urlVideoTopLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_TOP_RIGHT) {
        this.urlImageTopRight = asset.content;
      }
      if (
        asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO_TOP_RIGHT
      ) {
        this.urlVideoTopRight = asset.content;
      }
      if (
        asset.placeholderCode === PlaceholderCodes.BlobBased.URL_BOTTOM_LEFT
      ) {
        this.urlImageBottomLeft = asset.content;
      }
      if (
        asset.placeholderCode ===
        PlaceholderCodes.BlobBased.URL_VIDEO_BOTTOM_LEFT
      ) {
        this.urlVideoBottomLeft = asset.content;
      }
      if (
        asset.placeholderCode === PlaceholderCodes.BlobBased.URL_BOTTOM_RIGHT
      ) {
        this.urlImageBottomRight = asset.content;
      }
      if (
        asset.placeholderCode ===
        PlaceholderCodes.BlobBased.URL_VIDEO_BOTTOM_RIGHT
      ) {
        this.urlVideoBottomRight = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.HTML) {
        this.html = asset.content;
        this.htmlTemp = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_TOP_LEFT) {
        this.footnoteTopLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_TOP_RIGHT) {
        this.footnoteTopRight = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_BOTTOM_LEFT) {
        this.footnoteBottomLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_BOTTOM_RIGHT) {
        this.footnoteBottomRight = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedUrlVideoTopLeft = this.urlVideoTopLeft;
    this.activatedUrlVideoTopRight = this.urlVideoTopRight;
    this.activatedUrlVideoBottomLeft = this.urlVideoBottomLeft;
    this.activatedUrlVideoBottomRight = this.urlVideoBottomRight;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
  }

  public override onEditModeChanging(): void {
    this.html = this.htmlTemp;
  }

  public onTitleChange(title: string) {
    this.title = title;

    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onContentChange(content: string) {
    this.htmlTemp = content;

    this.handleAssetChange(this.htmlTemp, PlaceholderCodes.HTML);
  }

  public onUrlImageTopLeftChange(url: string) {
    this.urlImageTopLeft = url;

    this.handleAssetChange(
      this.urlImageTopLeft,
      PlaceholderCodes.BlobBased.URL_TOP_LEFT
    );
  }

  public onUrlVideoTopLeftChange(url: string) {
    this.activatedUrlVideoTopLeft = url;

    this.handleAssetChange(
      this.activatedUrlVideoTopLeft,
      PlaceholderCodes.BlobBased.URL_VIDEO_TOP_LEFT
    );
  }

  public onUrlImageTopRightChange(url: string) {
    this.urlImageTopRight = url;

    this.handleAssetChange(
      this.urlImageTopRight,
      PlaceholderCodes.BlobBased.URL_TOP_RIGHT
    );
  }

  public onUrlVideoTopRightChange(url: string) {
    this.activatedUrlVideoTopRight = url;

    this.handleAssetChange(
      this.activatedUrlVideoTopRight,
      PlaceholderCodes.BlobBased.URL_VIDEO_TOP_RIGHT
    );
  }

  public onUrlImageBottomLeftChange(url: string) {
    this.urlImageBottomLeft = url;

    this.handleAssetChange(
      this.urlImageBottomLeft,
      PlaceholderCodes.BlobBased.URL_BOTTOM_LEFT
    );
  }

  public onUrlVideoBottomLeftChange(url: string) {
    this.activatedUrlVideoBottomLeft = url;

    this.handleAssetChange(
      this.activatedUrlVideoBottomLeft,
      PlaceholderCodes.BlobBased.URL_VIDEO_BOTTOM_LEFT
    );
  }

  public onUrlImageBottomRightChange(url: string) {
    this.urlImageBottomRight = url;

    this.handleAssetChange(
      this.urlImageBottomRight,
      PlaceholderCodes.BlobBased.URL_BOTTOM_RIGHT
    );
  }

  public onUrlVideoBottomRightChange(url: string) {
    this.activatedUrlVideoBottomRight = url;

    this.handleAssetChange(
      this.activatedUrlVideoBottomRight,
      PlaceholderCodes.BlobBased.URL_VIDEO_BOTTOM_RIGHT
    );
  }

  public onFootnoteTopLeftChange(footnote: string) {
    this.footnoteTopLeft = footnote;

    this.handleAssetChange(
      this.footnoteTopLeft,
      PlaceholderCodes.FOOT_NOTE_TOP_LEFT
    );
  }

  public onFootnoteTopRightChange(footnote: string) {
    this.footnoteTopRight = footnote;

    this.handleAssetChange(
      this.footnoteTopRight,
      PlaceholderCodes.FOOT_NOTE_TOP_RIGHT
    );
  }

  public onFootnoteBottomLeftChange(footnote: string) {
    this.footnoteBottomLeft = footnote;

    this.handleAssetChange(
      this.footnoteBottomLeft,
      PlaceholderCodes.FOOT_NOTE_BOTTOM_LEFT
    );
  }

  public onFootnoteBottomRightChange(footnote: string) {
    this.footnoteBottomRight = footnote;

    this.handleAssetChange(
      this.footnoteBottomRight,
      PlaceholderCodes.FOOT_NOTE_BOTTOM_RIGHT
    );
  }
}
