<div class="plotly-right-template-container top-bottom-padding-template">
  <div class="content-container">
    <div class="left-side">
      <rh-plaintext-placeholder
        class="main-title"
        [fontSize]="'var(--font-size-title-smaller)'"
        [lineHeight]="'1em'"
        [fontColor]="'#254FAA'"
        [text]="title"
        [editMode]="editMode"
        (textChange)="onTitleChange($event)"
      ></rh-plaintext-placeholder>
      <rh-rich-text-placeholder
        class="description-control"
        [html]="html"
        [editMode]="editMode"
        (contentChange)="onContentChange($event)"
      >
      </rh-rich-text-placeholder>
    </div>
    <div class="right-side">
      <rh-iframe-plotly-placeholder
        class="plotly-placeholder"
        [src]="activatedPlotlyUrl"
        [editMode]="editMode"
        (urlChange)="onUrlChange($event)"
      ></rh-iframe-plotly-placeholder>
    </div>
  </div>
</div>
