import { Observable, of } from 'rxjs';
import { PermissionsService } from '../permissions.service';
import { IRoleModel } from '../../../models/role.model';
import { ROLE_ADMIN, ROLE_ADMIN_KEY, ROLE_APPROVER, ROLE_APPROVER_KEY, ROLE_EDITOR, ROLE_EDITOR_KEY, ROLE_VIEWER, ROLE_VIEWER_KEY } from '../../../constants/roles.constants';
import { IPermissionModel } from '../../../models/permission.model';

export class PermissionsServiceMock extends PermissionsService {
  private readerUserRoles: IRoleModel[] = [
    { key: ROLE_VIEWER_KEY, name: ROLE_VIEWER } as IRoleModel,
  ];

  private editorUserRoles: IRoleModel[] = [
    { key: ROLE_EDITOR_KEY, name: ROLE_EDITOR } as IRoleModel,
  ];

  private approverUserRoles: IRoleModel[] = [
    { key: ROLE_APPROVER_KEY, name: ROLE_APPROVER } as IRoleModel,
  ];

  private adminUserRoles: IRoleModel[] = [
    { key: ROLE_ADMIN_KEY, name: ROLE_ADMIN } as IRoleModel,
  ];

  override LoadPermissions(): Observable<IPermissionModel> {
    return of({
      roles: this.adminUserRoles,
      licences: [],
      organizationName: 'Mock Organization',
    } as IPermissionModel);
  }
}
