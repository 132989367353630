import { ICourseTile } from '../../../features/course-list/components/course-tile/course-tile.model';
import { Course } from '../../models/course.model';

export interface State {
  courses?: ICourseTile[];
  currentCourse?: Course;
  searchCoursesResult?: ICourseTile[];
  isLoading?: boolean;
  isCopying?: boolean;
  hasChanged: boolean;
  saveSuccess?: boolean;
  errorLoading?: string;
  error?: string;
  disableNavigation: boolean;
}

export const initialState: State = {
  hasChanged: false,
  disableNavigation: false,
};
