<div class="image-right-template-container top-bottom-padding-template">
  <div class="content-container">
    <div class="left-side">
      <rh-plaintext-placeholder
        class="main-title"
        [fontSize]="'var(--font-size-title-smaller)'"
        [lineHeight]="'1em'"
        [fontColor]="'#254FAA'"
        [text]="title"
        [editMode]="editMode"
        (textChange)="onTitleChange($event)"
      ></rh-plaintext-placeholder>
      <rh-rich-text-placeholder
        class="description-control"
        [html]="html"
        [editMode]="editMode"
        (contentChange)="onContentChange($event)"
      >
      </rh-rich-text-placeholder>
    </div>
    <div class="right-side">
      <rh-media-placeholder
        class="image-placeholder"
        [urlImage]="urlImage"
        [urlVideo]="activatedUrlVideo"
        [editMode]="editMode"
        [showZoomButton]="false"
        (zoomedChange)="onZoomedChanged($event)"
        (urlImageChange)="onUrlImageChange($event)"
        (urlVideoChange)="onUrlVideoChange($event)"
      ></rh-media-placeholder>
      <rh-plaintext-placeholder
        class="footnote"
        [fontSize]="'var(--font-size-small-text)'"
        [lineHeight]="'1em'"
        [fontColor]="'#4f4d4d'"
        [text]="footnote"
        [uppercase]="false"
        [editMode]="editMode"
        (textChange)="onFootnoteChange($event)"
      ></rh-plaintext-placeholder>
    </div>
  </div>
  <div *ngIf="zoomed" class="zoom-backdrop"></div>
</div>
