import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlaceholderBaseComponent } from '../core/placeholder-base.component';

@Component({
  selector: 'rh-plaintext-placeholder',
  templateUrl: './plaintext-placeholder.component.html',
  styleUrls: ['./plaintext-placeholder.component.scss'],
})
export class PlaintextPlaceholderComponent
  extends PlaceholderBaseComponent
  implements AfterViewChecked
{
  @ViewChild('textArea') public textArea!: ElementRef;

  @Input() public text = 'Text mssing!';
  @Output() public textChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Input() public fontColor = 'white';
  @Input() public editModeBackgroundColor = 'var(--rh-placeholder-background)';
  @Input() public fontSize = '70px';
  @Input() public fontFamily = 'Helvetica Neue Thin';
  @Input() public lineHeight: number | string = 20; // in px
  @Input() public textAlign = 'left';
  @Input() public uppercase = true;

  public get lineHeightString(): string {
    if (typeof this.lineHeight === 'number') {
      return this.lineHeight + 'px';
    }

    return this.lineHeight;
  }
  public get textTransform(): string {
    return this.uppercase ? 'uppercase' : 'none';
  }

  constructor(sanitizer: DomSanitizer, zone: NgZone) {
    super(sanitizer, zone);
  }

  ngAfterViewChecked(): void {
    this.adjustTextAreaHeight(); // Init correct height for initial content
  }

  onTextChange() {
    this.adjustTextAreaHeight();
    if (this.textChange) {
      this.textChange.emit(this.text);
    }
  }

  private adjustTextAreaHeight() {
    if (this.textArea) {
      this.textArea.nativeElement.style.height = 'auto';
      this.textArea.nativeElement.style.height =
        this.textArea.nativeElement.scrollHeight + 'px';
    }
  }
}
