import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageDropdownDesktopComponent } from './language-dropdown-desktop.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LanguageDropdownDesktopComponent],
  exports: [LanguageDropdownDesktopComponent],
})
export class LanguageDropdownDesktopModule {}
