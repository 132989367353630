import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';
import { environment } from '../../../../environments/environment';
import { ISlideModel } from '../../../core/models/slide.model';
import { ICourseTile } from '../../../features/course-list/components/course-tile/course-tile.model';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_COURSE_DATA: {
      return {
        ...state,
        currentCourse: undefined,
        errorLoading: undefined,
        saveSuccess: undefined,
        isLoading: true,
      };
    }
    case ActionTypes.LOAD_COURSE_DATA_SUCCESS: {
      return {
        ...state,
        currentCourse: {
          id: action.payload.courseId,
          versionNumber: action.payload.versionNumber,
          courseVersionId: action.payload.courseVersionId,
          slides: action.payload.slides.map((s) => transformSlideThumbnail(s)),
          approvalRequested: action.payload.approvalRequested,
          rejected: action.payload.rejected,
          approved: action.payload.approved,
          published: action.payload.published,
          title: action.payload.title,
          externalUrl: action.payload.externalUrl,
          categoryId: action.payload.categoryId,
          subcategoryId: action.payload.subcategoryId,
          author: action.payload.author,
        },
        errorLoading: undefined,
        saveSuccess: undefined,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_COURSE_DATA_FAILURE: {
      return {
        ...state,
        errorLoading: action.payload.error,
        isLoading: false,
      };
    }
    case ActionTypes.UNLOAD_COURSE_DATA: {
      return {
        ...state,
        currentCourse: undefined,
        error: undefined,
        errorLoading: undefined,
        saveSuccess: undefined,
        hasChanged: false,
      };
    }
    case ActionTypes.LOAD_COURSES_DATA: {
      return {
        ...state,
        courses: undefined,
        error: undefined,
        isLoading: true,
      };
    }
    case ActionTypes.LOAD_COURSES_DATA_SUCCESS: {
      return {
        ...state,
        courses: action.payload.courses.map((c) => transformImageUrl(c)),
        error: undefined,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_COURSES_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case ActionTypes.SEARCH_COURSES: {
      return {
        ...state,
        searchCoursesResult: undefined,
        error: undefined,
        isLoading: true,
      };
    }
    case ActionTypes.SEARCH_COURSES_SUCCESS: {
      return {
        ...state,
        searchCoursesResult: action.payload.searchresult,
        error: undefined,
        isLoading: false,
      };
    }
    case ActionTypes.SEARCH_COURSES_FAILURE: {
      return {
        ...state,
        searchCoursesResult: undefined,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case ActionTypes.COPY_COURSE: {
      return {
        ...state,
        isCopying: true,
      };
    }
    case ActionTypes.COPY_COURSE_SUCCESS: {
      return {
        ...state,
        isCopying: false,
        error: undefined,
        hasChanged: false,
      };
    }
    case ActionTypes.COPY_COURSE_FAILURE: {
      return {
        ...state,
        isCopying: false,
        saveSuccess: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.SAVE_COURSE_SUCCESS: {
      return {
        ...state,
        saveSuccess: true,
        error: undefined,
        hasChanged: false,
      };
    }
    case ActionTypes.SAVE_COURSE_FAILURE: {
      return {
        ...state,
        saveSuccess: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.CLEAR_COURSES_SEARCH: {
      return {
        ...state,
        searchCoursesResult: undefined,
      };
    }
    case ActionTypes.DELETE_COURSE_SUCCESS: {
      return {
        ...state,
        currentCourse: undefined,
      };
    }
    case ActionTypes.DELETE_COURSE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case ActionTypes.CHANGE_COURSE_DATA: {
      return {
        ...state,
        hasChanged: action.changed,
      };
    }
    case ActionTypes.RESET_STATE_CHANGE: {
      return {
        ...state,
        hasChanged: false,
        saveSuccess: undefined,
        error: undefined,
      };
    }
    case ActionTypes.REJECT_COURSE_SUCCESS: {
      const newState = {
        ...state,
        hasChanged: false,
        error: undefined,
      };

      if (newState.currentCourse) {
        newState.currentCourse.approvalRequested = '';
        newState.currentCourse.rejected = new Date().toISOString();
      }

      return newState;
    }
    case ActionTypes.APPROVE_COURSE_SUCCESS: {
      const newState = {
        ...state,
        hasChanged: false,
        error: undefined,
      };

      if (newState.currentCourse) {
        // newState.currentCourse.approvalRequested = '';
        newState.currentCourse.approved = new Date().toISOString();
      }

      return newState;
    }
    case ActionTypes.PUBLISH_COURSE: {
      const newState = {
        ...state,
        error: undefined,
      };

      return newState;
    }
    case ActionTypes.PUBLISH_COURSE_SUCCESS: {
      const newState = {
        ...state,
        hasChanged: false,
        error: undefined,
      };

      if (newState.currentCourse) {
        newState.currentCourse.published = new Date().toISOString(); // todo
      }

      return newState;
    }
    case ActionTypes.PUBLISH_COURSE_FAILURE: {
      const newState = {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
      return newState;
    }
    case ActionTypes.UNPUBLISH_COURSE: {
      const newState = {
        ...state,
        error: undefined,
      };

      return newState;
    }
    case ActionTypes.UNPUBLISH_COURSE_SUCCESS: {
      const newState = {
        ...state,
        hasChanged: false,
        error: undefined,
      };

      return newState;
    }
    case ActionTypes.UNPUBLISH_COURSE_FAILURE: {
      const newState = {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
      return newState;
    }
    case ActionTypes.SET_DISABLE_NAVIGATION: {
      return {
        ...state,
        disableNavigation: action.disable,
      };
    }
    default: {
      return state;
    }
  }
}

function transformSlideThumbnail(slide: ISlideModel): ISlideModel {
  const assets = slide.assets.map((a) => {
    const isNotBlobAsset =
      !a.placeholderCode.startsWith('URL') ||
      a.content.indexOf('assets/img') !== -1;
    if (isNotBlobAsset) {
      return a;
    }

    return {
      ...a,
    };
  });

  if (!slide.thumbnail) {
    return {
      ...slide,
    };
  }
  const thumbnail =
    slide.thumbnail?.indexOf('assets/img') === -1
      ? `${environment.backendApiUrl}/blobAssets/${slide.thumbnail}`
      : slide.thumbnail;

  return {
    ...slide,
    displayThumbnail: thumbnail,
    assets: assets,
  };
}

function transformImageUrl(course: ICourseTile): ICourseTile {
  if (!course.imageUrl) {
    return course;
  }

  const isNotPredefinedAsset = course.imageUrl.indexOf('assets/img') === -1;
  const isBase64Asset = course.imageUrl.startsWith('data:image');

  const imageUrl =
    isNotPredefinedAsset && !isBase64Asset
      ? `${environment.backendApiUrl}/blobAssets/${course.imageUrl}`
      : course.imageUrl;

  return {
    ...course,
    imageUrl: imageUrl,
  };
}
