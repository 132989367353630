import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { SunEditorOptions } from 'suneditor/src/options';
import { PlaceholderBaseComponent } from '../core/placeholder-base.component';

import {
  font,
  fontColor,
  formatBlock,
  list,
  math,
} from 'suneditor/src/plugins';

import katex from 'katex';
import { Core } from 'suneditor/src/lib/core';
import { NgxSuneditorComponent } from '../../ngx-suneditor/ngx-suneditor.component';

@Component({
  selector: 'rh-rich-text-placeholder',
  templateUrl: './rich-text-placeholder.component.html',
  styleUrls: ['./rich-text-placeholder.component.scss'],
})
export class RichTextPlaceholderComponent
  extends PlaceholderBaseComponent
  implements OnInit
{
  @ViewChild(NgxSuneditorComponent) public editor!: NgxSuneditorComponent;

  @Input()
  public defaultTextStyle =
    'font-family: Helvetica Neue Regular; font-size: clamp(16px, 2.4vw, 24px); line-height: 1.6em; color: #4F4D4D;';

  @Input()
  public html = '';

  @Output()
  public contentChange: EventEmitter<string> = new EventEmitter<string>();

  public editorOptions: SunEditorOptions = {};

  constructor(sanitizer: DomSanitizer, zone: NgZone) {
    super(sanitizer, zone);
  }

  public ngOnInit() {
    this.editorOptions = {
      minWidth: '100%',
      height: 'auto',
      katex: katex,
      plugins: [list, font, formatBlock, fontColor, math],
      className: 'sun-editor-editable-customizations',
      defaultStyle: this.defaultTextStyle + ' background-color: #CCCCCC;',
      resizingBar: false,
      buttonList: [
        ['undo', 'redo'],
        ['removeFormat', 'bold', 'fontColor'],
        ['list'],
        ['codeView'],
        ['math'],
        ['subscript', 'superscript'],
      ],
      mode: 'inline',
      colorList: ['#3d5996', '#504d4e', '#ffffff', '#93aad4', '#e00000'],
      attributesWhitelist: {
        all: 'style',
      },
    };
  }

  // We still need this method, since formatting changes are not reflected in the below event...
  public onChange(evt: { content: string; core: Core }) {
    if (this.contentChange) {
      this.contentChange.emit(evt.content);
    }
  }

  // The suneditor (native) method 'onChange' has a delay/debounce that we cannot control so
  // we can't set it to 0ms which is required in our concept -> we use angular's (onKeyUp)=...
  public onKeyPress() {
    if (this.editor && this.contentChange) {
      this.contentChange.emit(this.editor.getContents(false));
    }
  }
}
