import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  LanguageDropdownDesktopModule,
  LanguageDropdownMobileModule,
  LanguageDropdownMobileTabletModule,
} from '@myreishauer/languagedropdown';
import { LoadingModule } from '@myreishauer/loading';
import { UserDropdownModule } from '@myreishauer/userdropdown';
import { TranslateModule } from '@ngx-translate/core';
import { ApprovalControlsComponent } from './components/approval-controls/approval-controls.component';
import { BadgeComponent } from './components/badge/badge.component';
import { PPTButtonComponent } from './components/button-powerpoint/button-powerpoint.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmCancelDialogComponent } from './components/confirm-cancel-dialog/confirm-cancel-dialog.component';
import { ConfirmCancelCommentFieldComponent } from './components/confirm-cancel-dialog/core/confirm-cancel-comment-field/confirm-cancel-comment-field.component';
import { ConfirmCancelHeaderComponent } from './components/confirm-cancel-dialog/core/confirm-cancel-header/confirm-cancel-header.component';
import { ConfirmCancelToolbarComponent } from './components/confirm-cancel-dialog/core/confirm-cancel-toolbar/confirm-cancel-toolbar.component';
import { CourseOverviewComponent } from './components/course-overview/course-overview.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EditModeToggleComponent } from './components/edit-mode-toggle/edit-mode-toggle.component';
import { EditFabComponent } from './components/fab/fab.component';
import { HeaderComponent } from './components/header/header.component';
import { IconComponent } from './components/icon/icon.component';
import { LogoComponent } from './components/logo/logo.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { PreviewModeToggleComponent } from './components/preview-mode-toggle/preview-mode-toggle.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SimpleListComponent } from './components/simple-list/simple-list.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { TextboxComponent } from './components/textbox/textbox.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { VerticalMenuDropdownComponent } from './components/vertical-menu-dropdown/vertical-menu-dropdown.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { FilterBySubcategoryPipe } from './pipes/listfilter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SnackbarComponent } from './services/snackbar/snackbar.component';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterBySubcategoryPipe,
    DropdownComponent,
    TextboxComponent,
    CheckboxComponent,
    IconComponent,
    StatusBadgeComponent,
    EditFabComponent,
    BadgeComponent,
    ButtonComponent,
    MenuButtonComponent,
    PPTButtonComponent,
    SnackbarComponent,
    HeaderComponent,
    SidebarComponent,
    VerticalMenuComponent,
    VerticalMenuDropdownComponent,
    UserMenuComponent,
    EditModeToggleComponent,
    PreviewModeToggleComponent,
    ApprovalControlsComponent,
    LogoComponent,
    SimpleListComponent,
    ConfirmCancelDialogComponent,
    ConfirmCancelToolbarComponent,
    ConfirmCancelCommentFieldComponent,
    ConfirmCancelHeaderComponent,
    CourseOverviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LoadingModule,
    UserDropdownModule,
    LanguageDropdownDesktopModule,
    LanguageDropdownMobileTabletModule,
    LanguageDropdownMobileModule,
    TranslateModule.forChild({
      extend: true,
    }),
    LanguageDropdownMobileModule,
  ],
  exports: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterBySubcategoryPipe,
    DropdownComponent,
    TextboxComponent,
    CheckboxComponent,
    IconComponent,
    StatusBadgeComponent,
    EditFabComponent,
    BadgeComponent,
    ButtonComponent,
    MenuButtonComponent,
    PPTButtonComponent,
    SnackbarComponent,
    HeaderComponent,
    SidebarComponent,
    VerticalMenuComponent,
    VerticalMenuDropdownComponent,
    UserMenuComponent,
    EditModeToggleComponent,
    PreviewModeToggleComponent,
    ApprovalControlsComponent,
    LogoComponent,
    SimpleListComponent,
    ConfirmCancelDialogComponent,
    ConfirmCancelToolbarComponent,
    ConfirmCancelCommentFieldComponent,
    ConfirmCancelHeaderComponent,
  ],
})
export class CoreModule {}
