import { User } from '../../models/user.model';

export interface State {
  isLoading?: boolean;
  error?: string;
  language: string;
  approvers?: string[];
  editors?: string[];
  user?: User;
}

export const initialState: State = {
  language: 'en',
};
