import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        permissions: action.payload.permissions,
        error: undefined
      };
    }
    case ActionTypes.LOAD_PERMISSIONS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
