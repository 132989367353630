import { Category } from '../../models/category.model';

export interface State {
  categories: Category[];
  isLoading?: boolean;
  error?: string;
  selectedCategory?: string;
  sidebarCategory?: string;
}

export const initialState: State = {
  categories: [],
};
