<button
  [type]="type"
  [disabled]="disabled"
  [ngClass]="{ 'rh-button-dark-mode': darkMode, 'rh-button-red-mode': redMode }"
  (click)="handleClick()"
  class="flex items-center whitespace-nowrap px-6 py-1 justify-center bg-white hover:bg-brand-blue-100 border border-brand-gray-700 rounded-[5px] text-brand-blue-600 disabled:cursor-not-allowed disabled:pointer-events-none disabled:bg-brand-gray-300"
>
  <span [style.color]="textColor">{{ text | translate }}</span>
  <img
    *ngIf="imageUrl"
    [src]="imageUrl"
    class="h-[20px] w-[20px] ml-6"
    [ngClass]="{ 'button-icon': !naturalMode }"
  />
</button>
