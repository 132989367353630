import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rh-userdropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent {
  showUserDropdown = false;

  @Input() company?: string;
  @Input() username?: string;
  @Output() userLoggedOut: EventEmitter<void> = new EventEmitter();

  constructor(public translate: TranslateService) {}

  toggleUserDropdown(): void {
    this.showUserDropdown = !this.showUserDropdown;
  }

  logout() {
    this.userLoggedOut.emit();
  }
}
