import { Component, Input } from '@angular/core';
import { IDynamicComponent } from '../../../../../core/interfaces/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

@Component({
  selector: 'rh-plotly-right-template',
  templateUrl: './plotly-right-template.component.html',
  styleUrls: ['./plotly-right-template.component.scss'],
})
export class PlotlyRightTemplateComponent
  extends TemplateBaseComponent
  implements IDynamicComponent
{
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T6.svg';

  public static override templateId = 6;

  @Input() public title = '';
  @Input() public html = '';
  @Input() public url = '';

  private htmlTemp = '';

  // With this indirection we can delay the loading of the iframe content
  // until this slide is activated (navigated to) -> performance reasons
  public activatedPlotlyUrl = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL) {
        this.url = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.HTML) {
        this.html = asset.content;
        this.htmlTemp = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedPlotlyUrl = this.url;
    console.log('activated', this.constructor.name);
  }

  public override onEditModeChanging(): void {
    this.html = this.htmlTemp;
  }

  public onTitleChange(title: string) {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onUrlChange(url: string) {
    this.activatedPlotlyUrl = url;
    this.handleAssetChange(
      this.activatedPlotlyUrl,
      PlaceholderCodes.BlobBased.URL
    );
  }

  public onContentChange(content: string) {
    this.htmlTemp = content;
    this.handleAssetChange(this.htmlTemp, PlaceholderCodes.HTML);
  }
}
