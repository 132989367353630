import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from '../../../button/core/button-type.enum';

@Component({
  selector: 'rh-confirm-cancel-toolbar',
  templateUrl: './confirm-cancel-toolbar.component.html',
  styleUrls: ['./confirm-cancel-toolbar.component.scss'],
})
export class ConfirmCancelToolbarComponent {
  public ButtonTypeEnum = ButtonType;

  @Input() public confirmationButtonText!: string;

  @Input() public confirmButtonEnabled = true;

  @Output() public confirmChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor() {
    console.log(this.confirmationButtonText);
  }

  public confirm(): void {
    this.handleEvent(true);
  }

  public cancel(): void {
    this.handleEvent(false);
  }

  private handleEvent(val: boolean) {
    if (this.confirmChange) {
      this.confirmChange.emit(val);
    }
  }
}
