import { IAssetDto } from '../../../core/dto/asset-dto.model';
import { IMediaAssetDto } from '../../../core/dto/media-asset-dto.model';
import { ISlideModel } from '../../../core/models/slide.model';

export interface State {
  current?: ISlideModel;
  position: number;
  isOpen: boolean;
  saveSuccess?: boolean;
  duplicateSuccess?: boolean;
  progressUpdate?: IProgressUpdate;
  urlUpdate?: IUrlUpdate;
  assetsUpdate?: IMediaAssetDto[];
  assets?: IAssetDto[];
  deleteSuccess?: boolean;
  error?: string;
  zoomed?: boolean;
  slideToCopy?: ISlideModel;
  selectedCategory?: string;
}

export const initialState: State = {
  position: 0,
  isOpen: false,
  error: '',
};

export interface IProgressUpdate {
  progress?: number;
  placeholderCode: string;
}

export interface IUrlUpdate {
  urls: string[];
  placeholderCode: string;
}
