import { Component, Input } from '@angular/core';
import { IDynamicComponent } from '../../../../../core/interfaces/dynamic-component.interface';
import { OverlayMode } from '../../../enums/overlay-mode.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

@Component({
  selector: 'rh-group-headings-template',
  templateUrl: './group-headings-template.component.html',
  styleUrls: ['./group-headings-template.component.scss'],
})
export class GroupHeadingsTemplateComponent
  extends TemplateBaseComponent
  implements IDynamicComponent
{
  public overlayMode: OverlayMode = OverlayMode.Full;
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T10.svg';

  public static override templateId = 10;

  private defaultBackgroundUrl =
    './assets/img/template-backgrounds/Reishauer_Index.jpg';
  private _url: string = this.defaultBackgroundUrl;
  @Input() public get url(): string {
    return this._url;
  }
  public set url(val: string) {
    if (!val) {
      this._url = this.defaultBackgroundUrl;
    } else {
      this._url = val;
    }
  }

  public headingNumbers: { [key: string]: string } = {};
  public headingTitles: { [key: string]: string } = {};
  public headingSubTitles: { [key: string]: string } = {};

  public placeholderCodes = PlaceholderCodes;

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (
        asset.placeholderCode.indexOf(PlaceholderCodes.GROUP_HEADINGS_NUMBER) >=
        0
      ) {
        this.headingNumbers[asset.placeholderCode] = asset.content;
      }

      if (
        asset.placeholderCode.indexOf(PlaceholderCodes.GROUP_HEADINGS_TITLE) >=
        0
      ) {
        this.headingTitles[asset.placeholderCode] = asset.content;
      }

      if (
        asset.placeholderCode.indexOf(
          PlaceholderCodes.GROUP_HEADINGS_SUB_TITLE
        ) >= 0
      ) {
        this.headingSubTitles[asset.placeholderCode] = asset.content;
      }

      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL) {
        this.url = asset.content;
      }
    }
  }

  public override onActivated(): void {
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
  }

  public onNumberChange(placeholderName: string, number: string) {
    this.headingNumbers[placeholderName] = number;
    this.handleAssetChange(
      this.headingNumbers[placeholderName],
      placeholderName
    );
  }

  public onTitleChange(placeholderName: string, title: string) {
    this.headingTitles[placeholderName] = title;
    this.handleAssetChange(
      this.headingTitles[placeholderName],
      placeholderName
    );
  }

  public onSubTitleChange(placeholderName: string, subTitle: string) {
    this.headingSubTitles[placeholderName] = subTitle;
    this.handleAssetChange(
      this.headingSubTitles[placeholderName],
      placeholderName
    );
  }

  public onUrlChange(url: string) {
    this.url = url;
    this.handleAssetChange(this.url, PlaceholderCodes.BlobBased.URL);
  }
}
