import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ICourseTile } from '../../../features/course-list/components/course-tile/course-tile.model';
import { Category } from '../../../shared/models/category.model';
import { Subcategory } from '../../../shared/models/subcategory.model';
import {
  SetSelectedCategoryAction,
  SetSidebarCategoryAction,
} from '../../../shared/store/category/actions';
import { State } from '../../../shared/store/state';

@Component({
  selector: 'rh-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ width: '300px' })),
      state('false', style({ width: '20px' })),
      transition('0 => 1', [animate('500ms ease-in')]),
      transition('1 => 0', [animate('500ms ease-in')]),
    ]),
  ],
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Output() logoClicked = new EventEmitter<void>();
  @Output() toggleVerticalMenuEvent = new EventEmitter<void>();
  @Input() verticalMenuOpen!: boolean;
  @Input() adminMode!: boolean;
  @Input() showVerticalMenu!: boolean;

  error$!: Observable<string>;
  error?: string;
  categories$!: Observable<Category[]>;
  categories: Category[] = [];
  courses$!: Observable<ICourseTile[]>;
  courses: ICourseTile[] = [];
  sidebarCategory$!: Observable<string | undefined>;
  sidebarCategory: string | undefined;
  coursesAndCategoriesLoaded$!: Observable<boolean>;

  filteredCategories: Category[] = [];

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  animationDone = false;

  constructor(
    private store$: Store<State>,
    private responsive: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];

          const isVerticalMenuOpen =
            (this.isWeb || this.isTabletLandscape) && !this.verticalMenuOpen;
          if (isVerticalMenuOpen) {
            this.toggleVerticalMenu();
          }
        })
    );

    this.error$ = this.store$.select((state) => {
      return state.Category.error as string;
    });

    this.subscriptions.add(
      this.error$.subscribe((error: string) => {
        if (error) {
          this.error = error;
          console.error(error);
        }
      })
    );

    this.categories$ = this.store$.select((state) => {
      return state.Category.categories;
    });

    this.subscriptions.add(
      this.categories$.subscribe((categories: Category[]) => {
        if (categories) {
          this.categories = categories;
        }
      })
    );

    this.courses$ = this.store$.select((state) => {
      return state.Course.courses as ICourseTile[];
    });

    this.subscriptions.add(
      this.courses$.subscribe((courses: ICourseTile[]) => {
        if (courses) {
          this.courses = courses;
        }
      })
    );

    this.sidebarCategory$ = this.store$.select((state) => {
      return state.Category?.sidebarCategory;
    });

    this.subscriptions.add(
      this.sidebarCategory$.subscribe((sidebarCategory: string | undefined) => {
        if (
          sidebarCategory &&
          sidebarCategory !== '' &&
          sidebarCategory !== this.sidebarCategory
        ) {
          this.sidebarCategory = sidebarCategory;
          if (this.filteredCategories && this.filteredCategories.length > 0) {
            this.filteredCategories.forEach((c) => {
              if (sidebarCategory.split('-')[0] === 'cat') {
                c.selected = 'cat-' + c.id === sidebarCategory;
                c.toggled = 'cat-' + c.id === sidebarCategory;
              }
            });
          }
        } else if (!sidebarCategory || sidebarCategory === '') {
          this.sidebarCategory = undefined;
        }
      })
    );

    this.coursesAndCategoriesLoaded$ = this.store$.select((state) => {
      if (
        state.Course.courses &&
        state.Course.courses.length > 0 &&
        state.Category.categories &&
        state.Category.categories.length > 0
      ) {
        return true;
      }
      return false;
    });

    this.subscriptions.add(
      this.coursesAndCategoriesLoaded$.subscribe((loaded: boolean) => {
        if (loaded) {
          this.getFilteredCategories();
        }
      })
    );
  }

  logoClick() {
    this.logoClicked.emit();
  }

  toggleVerticalMenu(): void {
    this.toggleVerticalMenuEvent.emit();
  }

  isOnlyCategoryVisible(category: Category): boolean {
    const visibleCategories = this.categories.filter((c) => c.toggled);
    return (
      visibleCategories.length === 1 && visibleCategories[0].id === category.id
    );
  }

  getFilteredCategories() {
    this.filteredCategories = [];

    this.categories.forEach((category) => {
      const cat = { ...category };
      cat.selected = false;
      cat.toggled = false;
      cat.subcategories = this.getFilteredSubcategories(cat.subcategories);
      if (
        cat.subcategories &&
        cat.subcategories.length > 0 &&
        this.categoryHasCourses(cat.id)
      ) {
        this.filteredCategories.push(cat);
      }
    });
    if (!this.sidebarCategory) {
      this.filteredCategories[0].selected = true;
      this.filteredCategories[0].toggled = true;
      this.sidebarCategory = 'cat-' + this.filteredCategories[0].id;
      this.store$.dispatch(new SetSidebarCategoryAction(this.sidebarCategory));
    }
  }

  getFilteredSubcategories(subcategories?: Subcategory[]): Subcategory[] {
    if (!subcategories) {
      return [];
    }
    return subcategories.filter((subcategory) => {
      return this.subcategoryHasCourses(subcategory.id);
    });
  }

  categoryHasCourses(categoryId: number): boolean {
    return this.courses.some((course) => {
      return course.categoryId === categoryId;
    });
  }

  subcategoryHasCourses(subcategoryId: number): boolean {
    return this.courses.some((course) => {
      return course.subcategoryId === subcategoryId;
    });
  }

  toggleCategory(category: Category): void {
    this.filteredCategories.forEach((c) => {
      c.toggled = c.id === category.id ? !c.toggled : false;
      c.selected = c.id === category.id ? !c.selected : false;
    });
  }

  scrollIntoView(selectedCategory: string) {
    this.store$.dispatch(new SetSelectedCategoryAction(selectedCategory));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
