import { Component, Input } from '@angular/core';
import { IDynamicComponent } from '../../../../../core/interfaces/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

@Component({
  selector: 'rh-image-left-right-template',
  templateUrl: './image-left-right-template.component.html',
  styleUrls: ['./image-left-right-template.component.scss'],
})
export class ImageLeftRightTemplateComponent
  extends TemplateBaseComponent
  implements IDynamicComponent
{
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T4.svg';

  public static override templateId = 4;

  @Input() public urlImageLeft = '';
  @Input() public urlImageRight = '';
  @Input() public urlVideoLeft = '';
  @Input() public urlVideoRight = '';
  @Input() public title = '';
  @Input() public footnoteLeft = '';
  @Input() public footnoteRight = '';

  public activatedUrlVideoLeft = '';
  public activatedUrlVideoRight = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_LEFT) {
        this.urlImageLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO_LEFT) {
        this.urlVideoLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_RIGHT) {
        this.urlImageRight = asset.content;
      }
      if (
        asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO_RIGHT
      ) {
        this.urlVideoRight = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_LEFT) {
        this.footnoteLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_RIGHT) {
        this.footnoteRight = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedUrlVideoLeft = this.urlVideoLeft;
    this.activatedUrlVideoRight = this.urlVideoRight;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
  }

  public onTitleChange(title: string) {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onUrlImageLeftChange(url: string) {
    this.urlImageLeft = url;
    this.handleAssetChange(
      this.urlImageLeft,
      PlaceholderCodes.BlobBased.URL_LEFT
    );
  }

  public onUrlVideoLeftChange(url: string) {
    this.activatedUrlVideoLeft = url;
    this.handleAssetChange(
      this.activatedUrlVideoLeft,
      PlaceholderCodes.BlobBased.URL_VIDEO_LEFT
    );
  }

  public onUrlImageRightChange(url: string) {
    this.urlImageRight = url;
    this.handleAssetChange(
      this.urlImageRight,
      PlaceholderCodes.BlobBased.URL_RIGHT
    );
  }

  public onUrlVideoRightChange(url: string) {
    this.activatedUrlVideoRight = url;
    this.handleAssetChange(
      this.activatedUrlVideoRight,
      PlaceholderCodes.BlobBased.URL_VIDEO_RIGHT
    );
  }

  public onFootnoteLeftChange(footnote: string) {
    this.footnoteLeft = footnote;
    this.handleAssetChange(this.footnoteLeft, PlaceholderCodes.FOOT_NOTE_LEFT);
  }

  public onFootnoteRightChange(footnote: string) {
    this.footnoteRight = footnote;
    this.handleAssetChange(
      this.footnoteRight,
      PlaceholderCodes.FOOT_NOTE_RIGHT
    );
  }
}
