<div class="image-left-right-template-container top-bottom-padding-template">
  <div class="content-container">
    <rh-plaintext-placeholder
      class="main-title"
      [fontSize]="'var(--font-size-title-smaller)'"
      [lineHeight]="'1em'"
      [fontColor]="'#254FAA'"
      [text]="title"
      [editMode]="editMode"
      (textChange)="onTitleChange($event)"
    ></rh-plaintext-placeholder>
    <div class="images-container">
      <div class="image-container">
        <rh-media-placeholder
          class="image-placeholder"
          [urlImage]="urlImageLeft"
          [urlVideo]="activatedUrlVideoLeft"
          [editMode]="editMode"
          [showZoomButton]="false"
          (zoomedChange)="onZoomedChanged($event)"
          placeholderCode="left"
          (urlImageChange)="onUrlImageLeftChange($event)"
          (urlVideoChange)="onUrlVideoLeftChange($event)"
        ></rh-media-placeholder>
        <rh-plaintext-placeholder
          class="footnote"
          [fontSize]="'var(--font-size-small-text)'"
          [lineHeight]="'1em'"
          [fontColor]="'#4f4d4d'"
          [text]="footnoteLeft"
          [uppercase]="false"
          [editMode]="editMode"
          (textChange)="onFootnoteLeftChange($event)"
        ></rh-plaintext-placeholder>
      </div>
      <div class="image-container">
        <rh-media-placeholder
          class="image-placeholder"
          [urlImage]="urlImageRight"
          [urlVideo]="activatedUrlVideoRight"
          [editMode]="editMode"
          [showZoomButton]="false"
          (zoomedChange)="onZoomedChanged($event)"
          placeholderCode="right"
          (urlImageChange)="onUrlImageRightChange($event)"
          (urlVideoChange)="onUrlVideoRightChange($event)"
        ></rh-media-placeholder>
        <rh-plaintext-placeholder
          class="footnote"
          [fontSize]="'var(--font-size-small-text)'"
          [lineHeight]="'1em'"
          [fontColor]="'#4f4d4d'"
          [text]="footnoteRight"
          [uppercase]="false"
          [editMode]="editMode"
          (textChange)="onFootnoteRightChange($event)"
        ></rh-plaintext-placeholder>
      </div>
    </div>
  </div>
  <div *ngIf="zoomed" class="zoom-backdrop"></div>
</div>
