import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';

export enum ActionTypes {
  LOAD_APPROVERS = '[User] Load Approvers',
  LOAD_APPROVERS_SUCCESS = '[User] Load Approvers Success',
  LOAD_APPROVERS_FAILURE = '[User] Load Approvers Failure',
  LOAD_EDITORS_BY_SUB_CATEGORY = '[User] Load Editors',
  LOAD_EDITORS_BY_SUB_CATEGORY_SUCCESS = '[User] Load Editors Success',
  LOAD_EDITORS_BY_SUB_CATEGORY_FAILURE = '[User] Load Editors Failure',
  LOAD_USER = '[Auth] Load User',
  LOAD_USER_SUCCESS = '[Auth] Load User Success',
  LOAD_USER_FAILURE = '[Auth] Load User Failure',
  CHANGE_LANGUAGE = '[Auth] Change Language',
  CHANGE_LANGUAGE_SUCCESS = '[Auth] Change Language Success',
  CHANGE_LANGUAGE_FAILURE = '[Auth] Change Language Failure',
}

export class LoadApproversAction implements Action {
  readonly type = ActionTypes.LOAD_APPROVERS;
}

export class LoadApproversSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_APPROVERS_SUCCESS;
  constructor(public approvers: string[]) {}
}

export class LoadApproversFailureAction implements Action {
  readonly type = ActionTypes.LOAD_APPROVERS_FAILURE;
  constructor(public error: string) {}
}

export class LoadEditorsBySubCategoryAction implements Action {
  readonly type = ActionTypes.LOAD_EDITORS_BY_SUB_CATEGORY;
  constructor(public subCategoryName: string) {}
}

export class LoadEditorsBySubCategorySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_EDITORS_BY_SUB_CATEGORY_SUCCESS;
  constructor(public editors: string[]) {}
}

export class LoadEditorsBySubCategoryFailureAction implements Action {
  readonly type = ActionTypes.LOAD_EDITORS_BY_SUB_CATEGORY_FAILURE;
  constructor(public error: string) {}
}

export class LoadUserAction implements Action {
  readonly type = ActionTypes.LOAD_USER;
}

export class LoadUserSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_USER_SUCCESS;
  constructor(public user: User) {}
}

export class LoadUserFailureAction implements Action {
  readonly type = ActionTypes.LOAD_USER_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ChangeLanguageAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE;
  constructor(public languageKey: string, public userId: string) {}
}

export class ChangeLanguageSuccessAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE_SUCCESS;
}

export class ChangeLanguageFailureAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE_FAILURE;
  constructor(public error: string) {}
}

export type Actions =
  | LoadApproversAction
  | LoadApproversSuccessAction
  | LoadApproversFailureAction
  | LoadEditorsBySubCategoryAction
  | LoadEditorsBySubCategorySuccessAction
  | LoadEditorsBySubCategoryFailureAction
  | ChangeLanguageAction
  | ChangeLanguageSuccessAction
  | ChangeLanguageFailureAction
  | LoadUserAction
  | LoadUserSuccessAction
  | LoadUserFailureAction;
