import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rh-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent {
  @Input()
  public title?: string = '';
  @Input()
  public value?: string = '';
  @Input()
  public placeholder?: string = '';
  @Input()
  public invalid?: boolean = false;
  @Input()
  public disabled = false;
  @Input()
  public minLength = 2;
  @Input()
  public maxLength = 64;
  @Input()
  public blueMode = false;
  @Input()
  public showSearchIcon = false;

  @Output()
  public selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  public get hasValue(): boolean {
    return !!this.value && this.value.length > 0;
  }

  public changeText(event: FocusEvent): void {
    if (event && event.target) {
      const value = (event.target as HTMLInputElement).value;
      if (value.length >= this.minLength) {
        this.selectionChanged?.emit(value);
      }
    }
  }

  public onValueChanged(event: KeyboardEvent): void {
    this.valueChange?.emit((event.target as HTMLInputElement).value);
  }

  public async onResetClicked() {
    if (this.disabled) {
      return;
    }

    this.value = '';
    this.valueChange?.emit(this.value);
  }
}
