import { Modes } from '../../../core/constants/modes';

export interface State {
  error: string;
  mode: string;
  isEditMode: boolean;
  isApproverMode: boolean;
  isPreviewMode: boolean;
}

export const initialState: State = {
  error: '',
  mode: Modes.QueryStrings.VIEWER,
  isEditMode: false,
  isApproverMode: false,
  isPreviewMode: false,
};
