<div *ngIf="!isLoading" class="flex h-screen overflow-hidden">
  <!-- sidebar -->
  <rh-sidebar
    [mode]="mode"
    [adminMode]="adminMode"
    (toggleSidebarEvent)="toggleSidebarEvent()"
    [sidebarOpen]="sidebarOpen"
    (logoClicked)="logoClickedEvent()"
  />
  <rh-vertical-menu
    (toggleVerticalMenuEvent)="toggleVerticalMenuEvent()"
    [verticalMenuOpen]="verticalMenuOpen"
    (logoClicked)="logoClickedEvent()"
    [adminMode]="adminMode"
    [showVerticalMenu]="showVerticalMenu"
  ></rh-vertical-menu>

  <rh-user-menu
    *ngIf="userMenuOpen"
    (toggleUserMenuEvent)="toggleUserMenuEvent()"
  />

  <!-- main content-->
  <div class="flex flex-col w-full h-screen overflow-hidden md:overflow-auto">
    <!-- header -->
    <rh-header
      [mode]="mode"
      [adminMode]="adminMode"
      [title]="!!adminMode ? 'Admin' : headerTitle"
      [normalHeader]="normalHeader"
      [enableVerticalMenu]="true"
      (toggleSidebarEvent)="toggleSidebarEvent()"
      (logoClicked)="logoClickedEvent()"
      (toggleVerticalMenuEvent)="toggleVerticalMenuEvent()"
      (toggleUserMenuEvent)="toggleUserMenuEvent()"
    ></rh-header>
    <!-- main container area -->
    <main class="overflow-auto h-full" id="main">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>

<rh-loading
  class="loading-spinner-container"
  *ngIf="isLoading && !permissions && !error"
></rh-loading>
