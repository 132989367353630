import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PlaceholderCodes } from '../../../features/course-list/components/placeholders/core/placeholder-codes';
import { Course } from '../../../shared/models/course.model';
import { User } from '../../../shared/models/user.model';
import {
  LogOutUserAction,
  SetModeAction,
  TogglePreviewModeAction,
} from '../../../shared/store/auth/actions';
import { LoadingActiveAction } from '../../../shared/store/core/actions';
import {
  ApproveCourseAction,
  RejectCourseAction,
} from '../../../shared/store/course/actions';
import { State } from '../../../shared/store/state';
import { ChangeLanguageAction } from '../../../shared/store/user/actions';
import { Modes } from '../../constants/modes';
import { ROLE_APPROVER_KEY } from '../../constants/roles.constants';
import { IPermissionModel } from '../../models/permission.model';
import { ISlideModel } from '../../models/slide.model';
import { DialogService } from '../../services/dialog/dialog.service';
import { ConfirmCancelDialogComponent } from '../confirm-cancel-dialog/confirm-cancel-dialog.component';
import { IConfirmCancelDialogData } from '../confirm-cancel-dialog/core/confirm-cancel-dialog-data.interface';

@Component({
  selector: 'rh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions: Subscription = new Subscription();

  @Input() public title = '';
  @Input() public slides: ISlideModel[] = [];
  @Input() public normalHeader = true;
  @Input() public showPreviewControl = false;
  @Input() public showSlideCount = false;
  @Input() public slideCountText = '';
  @Input() public previewMode = false;
  @Input() public approvalMode = false;
  @Input() public publishMode = false;
  @Input() public adminMode = false;
  @Input() public mode = '';
  @Input() public enableVerticalMenu = false;
  @Output() logoClicked = new EventEmitter<void>();
  @Output() toggleSidebarEvent = new EventEmitter<void>();
  @Output() toggleVerticalMenuEvent = new EventEmitter<void>();
  @Output() toggleUserMenuEvent = new EventEmitter<void>();

  user$!: Observable<User | undefined>;
  user?: User;
  permissions$!: Observable<IPermissionModel | undefined>;
  permissions?: IPermissionModel;
  course$!: Observable<Course | undefined>;
  course?: Course;
  language$!: Observable<string>;
  selectedLanguage = 'en';

  public get editMode(): boolean {
    return this.mode === Modes.QueryStrings.EDIT;
  }

  public get approverMode(): boolean {
    return this.mode === Modes.QueryStrings.APPROVER;
  }

  public chapterTitle = '';

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  constructor(
    private store$: Store<State>,
    public dialog: DialogService,
    private responsive: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];
        }),
    );

    this.user$ = this.store$.select((state) => {
      return state.User.user;
    });

    this.permissions$ = this.store$.select((state) => {
      return state.Permissions?.permissions;
    });

    this.course$ = this.store$.select((state) => {
      return state.Course.currentCourse;
    });

    this.language$ = this.store$.select((state) => {
      return state.User.language;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      }),
    );

    this.subscriptions.add(
      this.permissions$?.subscribe(
        (permissions: IPermissionModel | undefined) => {
          if (permissions) {
            this.permissions = permissions;
          }
        },
      ),
    );

    this.subscriptions.add(
      this.course$?.subscribe((course: Course | undefined) => {
        if (course) {
          this.course = course;
        }
      }),
    );

    this.subscriptions.add(
      this.language$?.subscribe((lang: string) => {
        this.selectedLanguage = lang;
      }),
    );
  }

  public get isApprover(): boolean {
    return this.checkRole(ROLE_APPROVER_KEY);
  }

  checkRole(role: string): boolean {
    if (!this.permissions || !this.permissions.roles) {
      return false;
    }

    return this.permissions.roles.map((m) => m.key).indexOf(role) >= 0;
  }

  logoClick(): void {
    this.logoClicked.emit();
  }

  exitPresentation() {
    window.location.href = environment.applicationUrl;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['slideCountText']) {
      const currentSlideIndex = Number(this.slideCountText.split('/')[0]);
      this.chapterTitle = '';
      const slides = this.slides.slice(0, currentSlideIndex);
      const coverPageSlides = slides.filter(
        (slide) => slide.templateName === 'CoverPageTemplateComponent',
      );
      if (coverPageSlides.length > 0) {
        const titleAsset = coverPageSlides[
          coverPageSlides.length - 1
        ].assets.find(
          (asset) => asset.placeholderCode === PlaceholderCodes.TITLE,
        );
        if (titleAsset) {
          this.chapterTitle = ` / ${titleAsset.content}`;
        }
      }
    }
  }

  public async changeMode(mode: string) {
    this.store$.dispatch(new SetModeAction({ mode: mode }));
  }

  public async togglePreviewMode() {
    this.previewMode = !this.previewMode;
    this.store$.dispatch(new TogglePreviewModeAction());
  }

  public async approve() {
    if (!this.course) {
      return;
    }
    this.store$.dispatch(new LoadingActiveAction());
    this.store$.dispatch(
      new ApproveCourseAction({ courseVersionId: this.course.courseVersionId }),
    );
  }

  public async reject() {
    const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
      data: {
        title: 'popups.reject.title',
        text: 'popups.reject.message',
        requireComment: true,
      } as IConfirmCancelDialogData,
    });

    const dialogResult = await lastValueFrom(dialogRef.afterClosed());

    if (!dialogResult.decision || !this.course) {
      return;
    }

    this.store$.dispatch(new LoadingActiveAction());
    this.store$.dispatch(
      new RejectCourseAction({
        courseVersionId: this.course.courseVersionId,
        rejectionComment: dialogResult.comment,
      }),
    );
  }

  toggleSidebar(): void {
    this.toggleSidebarEvent.emit();
  }

  toggleVerticalMenu(): void {
    this.toggleVerticalMenuEvent.emit();
  }

  toggleUserMenu(): void {
    this.toggleUserMenuEvent.emit();
  }

  changeLanguage(lang: string): void {
    if (!this.user) return;
    this.store$.dispatch(new ChangeLanguageAction(lang, this.user.sub));
  }

  logout(): void {
    localStorage.clear();
    this.store$.dispatch(new LogOutUserAction());
  }
}
