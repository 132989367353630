import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { Observable } from 'rxjs';
import { ISlideModel } from '../models/slide.model';

@Injectable({
  providedIn: 'root',
})
export class PDFGeneratorService {
  generate(slides: ISlideModel[], title: string): Observable<any> {
    return new Observable((subscriber) => {
      setTimeout(async () => {
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [1920, 1080],
        });

        for (let indexSlide = 0; indexSlide < slides.length; indexSlide++) {
          const slide = slides[indexSlide];

          if (!slide.displayThumbnail) continue;
          if (indexSlide !== 0) pdf.addPage();

          const image = new Image();
          image.src = slide.displayThumbnail;

          pdf.addImage(image, 'JPEG', 0, 0, 1920, 1080);
        }

        pdf.save(`${title}.pdf`);

        subscriber.next();
        subscriber.complete();
      }, 0);
    });
  }
}
