import { Component, Input } from '@angular/core';
import { IDynamicComponent } from '../../../../../core/interfaces/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

@Component({
  selector: 'rh-image-left-plotly-right',
  templateUrl: './image-left-plotly-right.component.html',
  styleUrls: ['./image-left-plotly-right.component.scss'],
})
export class ImageLeftPlotlyRightComponent
  extends TemplateBaseComponent
  implements IDynamicComponent
{
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T3.svg';

  public static override templateId = 3;

  @Input() public title = '';
  @Input() public urlLeft = '';
  @Input() public urlRight = ''; // Plotly url

  // With this indirection we can delay the loading of the iframe content
  // until this slide is activated (navigated to)
  public activatedPlotlyUrl = '';
  public activatedUrlVideoLeft = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_LEFT) {
        this.urlLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_RIGHT) {
        this.urlRight = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedPlotlyUrl = this.urlRight;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
  }

  public onImageUrlChange(url: string) {
    this.urlLeft = url;
    this.handleAssetChange(this.urlLeft, PlaceholderCodes.BlobBased.URL_LEFT);
  }

  public onUrlVideoTopLeftChange(url: string) {
    this.activatedUrlVideoLeft = url;

    this.handleAssetChange(
      this.activatedUrlVideoLeft,
      PlaceholderCodes.BlobBased.URL_VIDEO_LEFT
    );
  }

  public onPlotlyUrlChange(url: string) {
    this.activatedPlotlyUrl = url;
    this.handleAssetChange(
      this.activatedPlotlyUrl,
      PlaceholderCodes.BlobBased.URL_RIGHT
    );
  }
}
