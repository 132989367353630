import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { IConfirmationResult } from '../../../core/components/confirm-cancel-dialog/core/confirmation-result.model';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef {
  private afterClosedSubject = new Subject<IConfirmationResult>();
  private previousBodyOverflowStyle = '';

  constructor(protected overlayRef: OverlayRef) {
    this.previousBodyOverflowStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden'; // prevent scrolling background
  }

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  public close(result?: IConfirmationResult) {
    this.overlayRef.dispose();
    if (result) {
      this.afterClosedSubject.next(result);
    } else {
      this.afterClosedSubject.next({} as IConfirmationResult);
    }
    this.afterClosedSubject.complete();
    document.body.style.overflow = this.previousBodyOverflowStyle;
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  public afterClosed(): Observable<IConfirmationResult> {
    return this.afterClosedSubject.asObservable();
  }
}
