// If you change something here, sync it with the backend counterpart
export class PlaceholderCodes {
  static BlobBased = class {
    public static URL = 'URL';
    public static URL_IMAGE = 'URL_IMAGE';
    public static URL_VIDEO = 'URL_VIDEO';
    public static URL_LEFT = 'URL_LEFT';
    public static URL_VIDEO_LEFT = 'URL_VIDEO_LEFT';
    public static URL_RIGHT = 'URL_RIGHT';
    public static URL_VIDEO_RIGHT = 'URL_VIDEO_RIGHT';
    public static URL_TOP = 'URL_TOP';
    public static URL_VIDEO_TOP = 'URL_VIDEO_TOP';
    public static URL_BOTTOM = 'URL_BOTTOM';
    public static URL_VIDEO_BOTTOM = 'URL_VIDEO_BOTTOM';
    public static URL_TOP_LEFT = 'URL_TOP_LEFT';
    public static URL_VIDEO_TOP_LEFT = 'URL_VIDEO_TOP_LEFT';
    public static URL_TOP_RIGHT = 'URL_TOP_RIGHT';
    public static URL_VIDEO_TOP_RIGHT = 'URL_VIDEO_TOP_RIGHT';
    public static URL_BOTTOM_LEFT = 'URL_BOTTOM_LEFT';
    public static URL_VIDEO_BOTTOM_LEFT = 'URL_VIDEO_BOTTOM_LEFT';
    public static URL_BOTTOM_RIGHT = 'URL_BOTTOM_RIGHT';
    public static URL_VIDEO_BOTTOM_RIGHT = 'URL_VIDEO_BOTTOM_RIGHT';
  };

  public static TITLE = 'TITLE';
  public static SUB_TITLE = 'SUB_TITLE';

  public static FOOT_NOTE = 'FOOT_NOTE';
  public static FOOT_NOTE_LEFT = 'FOOT_NOTE_LEFT';
  public static FOOT_NOTE_RIGHT = 'FOOT_NOTE_RIGHT';
  public static FOOT_NOTE_TOP = 'FOOT_NOTE_TOP';
  public static FOOT_NOTE_BOTTOM = 'FOOT_NOTE_BOTTOM';
  public static FOOT_NOTE_TOP_LEFT = 'FOOT_NOTE_TOP_LEFT';
  public static FOOT_NOTE_TOP_RIGHT = 'FOOT_NOTE_TOP_RIGHT';
  public static FOOT_NOTE_BOTTOM_LEFT = 'FOOT_NOTE_BOTTOM_LEFT';
  public static FOOT_NOTE_BOTTOM_RIGHT = 'FOOT_NOTE_BOTTOM_RIGHT';

  public static HTML = 'HTML';

  public static COVER_PAGE_NUMBER = 'COVER_PAGE_NUMBER';

  public static GROUP_HEADINGS_NUMBER = 'GROUP_HEADINGS_NUMBER';
  public static GROUP_HEADINGS_NUMBER_1 = `${this.GROUP_HEADINGS_NUMBER}_1`;
  public static GROUP_HEADINGS_NUMBER_2 = `${this.GROUP_HEADINGS_NUMBER}_2`;
  public static GROUP_HEADINGS_NUMBER_3 = `${this.GROUP_HEADINGS_NUMBER}_3`;
  public static GROUP_HEADINGS_NUMBER_4 = `${this.GROUP_HEADINGS_NUMBER}_4`;
  public static GROUP_HEADINGS_NUMBER_5 = `${this.GROUP_HEADINGS_NUMBER}_5`;
  public static GROUP_HEADINGS_NUMBER_6 = `${this.GROUP_HEADINGS_NUMBER}_6`;

  public static GROUP_HEADINGS_TITLE = 'GROUP_HEADINGS_TITLE';
  public static GROUP_HEADINGS_TITLE_1 = `${this.GROUP_HEADINGS_TITLE}_1`;
  public static GROUP_HEADINGS_TITLE_2 = `${this.GROUP_HEADINGS_TITLE}_2`;
  public static GROUP_HEADINGS_TITLE_3 = `${this.GROUP_HEADINGS_TITLE}_3`;
  public static GROUP_HEADINGS_TITLE_4 = `${this.GROUP_HEADINGS_TITLE}_4`;
  public static GROUP_HEADINGS_TITLE_5 = `${this.GROUP_HEADINGS_TITLE}_5`;
  public static GROUP_HEADINGS_TITLE_6 = `${this.GROUP_HEADINGS_TITLE}_6`;

  public static GROUP_HEADINGS_SUB_TITLE = 'GROUP_HEADINGS_SUB_TITLE';
  public static GROUP_HEADINGS_SUB_TITLE_1 = `${this.GROUP_HEADINGS_SUB_TITLE}_1`;
  public static GROUP_HEADINGS_SUB_TITLE_2 = `${this.GROUP_HEADINGS_SUB_TITLE}_2`;
  public static GROUP_HEADINGS_SUB_TITLE_3 = `${this.GROUP_HEADINGS_SUB_TITLE}_3`;
  public static GROUP_HEADINGS_SUB_TITLE_4 = `${this.GROUP_HEADINGS_SUB_TITLE}_4`;
  public static GROUP_HEADINGS_SUB_TITLE_5 = `${this.GROUP_HEADINGS_SUB_TITLE}_5`;
  public static GROUP_HEADINGS_SUB_TITLE_6 = `${this.GROUP_HEADINGS_SUB_TITLE}_6`;
}
