import { Action } from '@ngrx/store';

export enum ActionTypes {
  SET_LOADING_ACTIVE = 'Set loading active',
  SET_LOADING_INACTIVE = 'Set loading inactive',
}

export class LoadingActiveAction implements Action {
  readonly type = ActionTypes.SET_LOADING_ACTIVE;
}

export class LoadingInActiveAction implements Action {
  readonly type = ActionTypes.SET_LOADING_INACTIVE;
}

export type Actions = LoadingActiveAction | LoadingInActiveAction;
