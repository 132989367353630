<div class="dropdown-container" [ngClass]="{ 'blue-mode': blueMode }">
  <div
    *ngIf="controlTitle"
    class="control-title text-[11px] font-brand-regular text-brand-blue-400 uppercase"
  >
    {{ controlTitle | translate }}
  </div>
  <div class="dropdown-container-inner">
    <input
      [ngClass]="
        model
          ? 'dropdown-input'
          : 'dropdown-input' + (invalidIfEmpty ? ' invalid' : '')
      "
      class="font-brand-regular text-lg"
      type="text"
      spellcheck="false"
      [value]="model?.name || '' | translate"
      [disabled]="disabled"
      (click)="toggle()"
      (focusout)="onFocusOutEvent()"
    />
    <div class="options-list font-brand-regular text-lg" *ngIf="listVisible">
      <div
        [ngClass]="{ selected: isSelected(item) }"
        *ngFor="let item of items"
        class="option"
        [title]="item.name | translate"
        (click)="select(item)"
      >
        {{ item.name | translate }}
      </div>
    </div>

    <div class="item-selector">
      <div class="svg-container">
        <img
          *ngIf="!blueMode"
          [ngClass]="{ 'rotated-arrow': listVisible }"
          src="./assets/img/buttons/dropdown.svg"
        />
        <img
          *ngIf="blueMode"
          [ngClass]="{ 'rotated-arrow': listVisible }"
          src="./assets/img/buttons/dropdown-white.svg"
        />
      </div>
    </div>
  </div>
</div>
