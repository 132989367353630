import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IAssetDto } from '../../../core/dto/asset-dto.model';
import { IMediaAssetDto } from '../../../core/dto/media-asset-dto.model';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private http: HttpClient) {}

  SaveAssets(assets: IAssetDto[]): Observable<IAssetDto[]> {
    return this.http
      .post<{
        assets: IAssetDto[];
      }>(`${environment.backendApiUrl}/SaveAssets?code=${environment.apiKey}`, {
        assets: assets,
      })
      .pipe(
        map((response) => {
          return response.assets;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  SaveTemporaryAsset(files: File[]): Observable<HttpEvent<object>> {
    const formData = new FormData();

    formData.append('mainFile', files[0]);
    for (let i = 1; i < files.length; i++) {
      const file = files[i];
      formData.append(`file${i}`, file);
    }

    return this.http
      .post(
        `${environment.backendApiUrl}/SaveTemporaryBlobAsset?code=${environment.apiKey}`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  GetMediaAssets(
    mediaType: string,
    searchText: string,
    categoryId: number,
    timeFrame: number,
    editorId: string,
    privateOnly: boolean,
    sortBy: string,
    sortDir: string
  ): Observable<IMediaAssetDto[]> {
    return this.http
      .get<IMediaAssetDto[]>(
        `${environment.backendApiUrl}/GetMediaAssets?code=${environment.apiKey}&mediaType=${mediaType}&searchText=${searchText}&categoryId=${categoryId}&timeFrame=${timeFrame}&editorId=${editorId}&privateOnly=${privateOnly}&sortBy=${sortBy}&sortDir=${sortDir}`
      )
      .pipe(
        map((response: IMediaAssetDto[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  GetMediaAssetEditors(): Observable<string[]> {
    return this.http
      .get<string[]>(
        `${environment.backendApiUrl}/GetMediaAssetEditors?code=${environment.apiKey}`
      )
      .pipe(
        map((response: string[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  SaveMediaAssets(assets: IMediaAssetDto[]): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/SaveMediaAssets?code=${environment.apiKey}`,
        { mediaAssets: assets }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  DeleteMediaAsset(assetId: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteMediaAsset?code=${environment.apiKey}&id=${assetId}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  SaveTemporaryMediaAsset(files: File[]): Observable<HttpEvent<object>> {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append(`file${i}`, file);
    }

    return this.http
      .post<string[]>(
        `${environment.backendApiUrl}/SaveTemporaryMediaAsset?code=${environment.apiKey}`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  DeleteTemporaryMediaAsset(mediaAssetId: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteTemporaryMediaAsset/${mediaAssetId}?code=${environment.apiKey}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  SaveThumbnails(slideId: number, thumbnail: string): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/SaveThumbnails?code=${environment.apiKey}`,
        { slideId: slideId, thumbnail: thumbnail }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }
}
