<div class="confirm-cancel-toolbar">
  <div class="buttons">
    <button class="button cancel-button" (click)="cancel()">
      {{ "popups.cancel" | translate }}
    </button>
    <button
      [disabled]="!confirmButtonEnabled"
      [ngClass]="{ 'disabled-button': !confirmButtonEnabled }"
      class="button confirm-button"
      (click)="confirm()"
    >
      <span *ngIf="confirmationButtonText">
        {{ confirmationButtonText | translate }}
      </span>
      <span *ngIf="!confirmationButtonText">
        {{ "popups.ok" | translate }}
      </span>
    </button>
  </div>
</div>
