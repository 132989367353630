import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rh-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input()
  public value = false;

  @Input()
  public label = '';

  @Input()
  public blueMode = false;

  @Output()
  public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public handleClick(): void {
    this.valueChange?.emit(this.value);
  }
}
