import { Action } from '@ngrx/store';
import { ICourseVersionModel } from '../../../core/models/course-version.model';
import { ISlideModel } from '../../../core/models/slide.model';
import { ICourseTile } from '../../../features/course-list/components/course-tile/course-tile.model';
import { Course } from '../../models/course.model';

export enum ActionTypes {
  LOAD_COURSES_DATA = '[Course] Load all Courses Data',
  LOAD_COURSES_DATA_SUCCESS = '[Course] Load all Courses Data Success',
  LOAD_COURSES_DATA_FAILURE = '[Course] Load all Courses Data Failure',
  LOAD_COURSE_DATA = '[Course] Load Course Data',
  LOAD_COURSE_DATA_SUCCESS = '[Course] Load Course Data Success',
  LOAD_COURSE_DATA_FAILURE = '[Course] Load Course Data Failure',
  UNLOAD_COURSE_DATA = '[Course] Unload current Course',
  LOAD_COURSE_HISTORY = '[Course] Load Course History',
  LOAD_COURSE_HISTORY_SUCCESS = '[Course] Load Course History Success',
  LOAD_COURSE_HISTORY_FAILURE = '[Course] Load Course History Failure',
  SEARCH_COURSES = '[Course] Search Courses',
  SEARCH_COURSES_SUCCESS = '[Course] Search Courses Success',
  SEARCH_COURSES_FAILURE = '[Course] Search Courses Failure',
  CLEAR_COURSES_SEARCH = '[Course] Clear Courses Search Criteria',
  SAVE_COURSE = '[Course] Save Course',
  SAVE_COURSE_SUCCESS = '[Course] Save Course Success',
  SAVE_COURSE_FAILURE = '[Course] Save Course Failure',
  COPY_COURSE = '[Course] Copy Course',
  COPY_COURSE_SUCCESS = '[Course] Copy Course Success',
  COPY_COURSE_FAILURE = '[Course] Copy Course Failure',
  REQUEST_APPROVAL_COURSE_VERSION = '[Course] Request Appproval for Course version',
  REQUEST_APPROVAL_COURSE_VERSION_SUCCESS = '[Course] Request Appproval for Course Version Success',
  REQUEST_APPROVAL_COURSE_VERSION_FAILURE = '[Course] Request Appproval for Course Version Failure',
  REQUEST_APPROVAL_COURSE_VERSION_CANCEL = '[Course] Cancel Appproval Request for Course version',
  REQUEST_APPROVAL_COURSE_VERSION_CANCEL_SUCCESS = '[Course] Cancel Appproval Request for Course Version Success',
  REQUEST_APPROVAL_COURSE_VERSION_CANCEL_FAILURE = '[Course] Cancel Appproval Request for Course Version Failure',
  UNAPPROVE = '[Course] Un-Approve for Course version',
  UNAPPROVE_SUCCESS = '[Course] Un-Approve for Course Version Success',
  UNAPPROVE_FAILURE = '[Course] Un-Approve for Course Version Failure',
  CREATE_NEW_COURSE_VERSION = '[Course] Create new Course version',
  CREATE_NEW_COURSE_VERSION_SUCCESS = '[Course] Create new Course Version Success',
  CREATE_NEW_COURSE_VERSION_FAILURE = '[Course] Create new Course Version Failure',
  DELETE_COURSE = '[Course] Delete Course',
  DELETE_COURSE_SUCCESS = '[Course] Delete Success',
  DELETE_COURSE_FAILURE = '[Course] Delete Failure',
  CHANGE_COURSE_DATA = '[Course] Change Course',
  RESET_STATE_CHANGE = '[Course] Reset State Change',
  APPROVE_COURSE = '[Course] Approve Course',
  APPROVE_COURSE_SUCCESS = '[Course] Approve Course Success',
  APPROVE_COURSE_FAILURE = '[Course] Approve Course Failure',
  REJECT_COURSE = '[Course] Reject Course',
  REJECT_COURSE_SUCCESS = '[Course] Reject Course Success',
  REJECT_COURSE_FAILURE = '[Course] Reject Course Failure',
  PUBLISH_COURSE = '[Course] Publish Course',
  PUBLISH_COURSE_SUCCESS = '[Course] Publish Course Success',
  PUBLISH_COURSE_FAILURE = '[Course] Publish Course Failure',
  UNPUBLISH_COURSE = '[Course] Unpublish Course',
  UNPUBLISH_COURSE_SUCCESS = '[Course] Unpublish Course Success',
  UNPUBLISH_COURSE_FAILURE = '[Course] Unpublish Course Failure',
  SET_DISABLE_NAVIGATION = '[Course] Set Disable Navigation',
  GENERATE_PDF = '[Course] Generate PDF',
  GENERATE_PDF_SUCCESS = '[Course] Generate PDF Success',
  GENERATE_PDF_FAILURE = '[Course] Generate PDF Failure',
}

export class ResetStateAction implements Action {
  readonly type = ActionTypes.RESET_STATE_CHANGE;
}

export class UnloadCourseAction implements Action {
  readonly type = ActionTypes.UNLOAD_COURSE_DATA;
}

export class ChangeCourseDataAction implements Action {
  readonly type = ActionTypes.CHANGE_COURSE_DATA;
  constructor(public changed: boolean) {}
}

export class LoadCoursesAction implements Action {
  readonly type = ActionTypes.LOAD_COURSES_DATA;
  constructor(public payload: { mode: string }) {}
}

export class LoadCoursesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COURSES_DATA_SUCCESS;
  constructor(public payload: { courses: ICourseTile[] }) {}
}

export class LoadCoursesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COURSES_DATA_FAILURE;
  constructor(public payload: { error: string }) {}
}

// Load course
export class LoadCourseAction implements Action {
  readonly type = ActionTypes.LOAD_COURSE_DATA;
  constructor(public payload: { id: number; versionNumber: number }) {}
}

export class LoadCourseSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COURSE_DATA_SUCCESS;
  constructor(
    public payload: {
      courseId: number;
      title: string;
      versionNumber: number;
      courseVersionId: number;
      approvalRequested: string;
      rejected: string;
      approved: string;
      published: string;
      slides: ISlideModel[];
      categoryId: number;
      subcategoryId: number;
      externalUrl: string;
      author: string;
    }
  ) {}
}

export class LoadCourseFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COURSE_DATA_FAILURE;
  constructor(public payload: { error: string }) {}
}

// Load course history
export class LoadCourseHistoryAction implements Action {
  readonly type = ActionTypes.LOAD_COURSE_HISTORY;
  constructor(public payload: { courseId: number }) {}
}

export class LoadCourseHistorySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COURSE_HISTORY_SUCCESS;
  constructor(public payload: ICourseVersionModel[]) {}
}

export class LoadCourseHistoryFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COURSE_HISTORY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SearchCoursesAction implements Action {
  readonly type = ActionTypes.SEARCH_COURSES;
  constructor(public payload: { searchcriteria: string }) {}
}

export class SearchCoursesSuccessAction implements Action {
  readonly type = ActionTypes.SEARCH_COURSES_SUCCESS;
  constructor(public payload: { searchresult: ICourseTile[] }) {}
}

export class SearchCoursesFailureAction implements Action {
  readonly type = ActionTypes.SEARCH_COURSES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ClearCoursesSearchAction implements Action {
  readonly type = ActionTypes.CLEAR_COURSES_SEARCH;
}

export class SaveCourseAction implements Action {
  readonly type = ActionTypes.SAVE_COURSE;
  constructor(public payload: { course: ICourseTile; mode: string }) {}
}

export class SaveCourseSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_COURSE_SUCCESS;
  constructor(public payload: { courseId: number; mode: string }) {}
}

export class SaveCourseFailureAction implements Action {
  readonly type = ActionTypes.SAVE_COURSE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CopyCourseAction implements Action {
  readonly type = ActionTypes.COPY_COURSE;
  constructor(public payload: { course: Course }) {}
}

export class CopyCourseSuccessAction implements Action {
  readonly type = ActionTypes.COPY_COURSE_SUCCESS;
}

export class CopyCourseFailureAction implements Action {
  readonly type = ActionTypes.COPY_COURSE_FAILURE;
  constructor(public payload: { error: string }) {}
}

/* Approval request */
export class ApprovalRequestAction implements Action {
  readonly type = ActionTypes.REQUEST_APPROVAL_COURSE_VERSION;
  constructor(public payload: { course: Course }) {}
}

export class ApprovalRequestSuccessAction implements Action {
  readonly type = ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_SUCCESS;
}

export class ApprovalRequestFailureAction implements Action {
  readonly type = ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ApprovalRequestCancelAction implements Action {
  readonly type = ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_CANCEL;
  constructor(public payload: { courseVersionId: number }) {}
}

export class ApprovalRequestCancelSuccessAction implements Action {
  readonly type = ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_CANCEL_SUCCESS;
}

export class ApprovalRequestCancelFailureAction implements Action {
  readonly type = ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_CANCEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UnapproveAction implements Action {
  readonly type = ActionTypes.UNAPPROVE;
  constructor(public payload: { courseVersionId: number }) {}
}

export class UnapproveSuccessAction implements Action {
  readonly type = ActionTypes.UNAPPROVE_SUCCESS;
}

export class UnapproveFailureAction implements Action {
  readonly type = ActionTypes.UNAPPROVE_FAILURE;
  constructor(public payload: { error: string }) {}
}

/* Create new Version */
export class CreateNewCourseVersionAction implements Action {
  readonly type = ActionTypes.CREATE_NEW_COURSE_VERSION;
  constructor(public payload: { course: Course; newCourseVerion: number }) {}
}

export class CreateNewCourseVersionSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_NEW_COURSE_VERSION_SUCCESS;
  constructor(public payload: { newCourseVerion: number }) {}
}

export class CreateNewCourseVersionFailureAction implements Action {
  readonly type = ActionTypes.CREATE_NEW_COURSE_VERSION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteCourseAction implements Action {
  readonly type = ActionTypes.DELETE_COURSE;
  constructor(public payload: { id: number }) {}
}

export class DeleteCourseSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_COURSE_SUCCESS;
}

export class DeleteCourseFailureAction implements Action {
  readonly type = ActionTypes.DELETE_COURSE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ApproveCourseAction implements Action {
  readonly type = ActionTypes.APPROVE_COURSE;
  constructor(public payload: { courseVersionId: number }) {}
}

export class ApproveCourseSuccessAction implements Action {
  readonly type = ActionTypes.APPROVE_COURSE_SUCCESS;
}

export class ApproveCourseFailureAction implements Action {
  readonly type = ActionTypes.APPROVE_COURSE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class RejectCourseAction implements Action {
  readonly type = ActionTypes.REJECT_COURSE;
  constructor(
    public payload: { courseVersionId: number; rejectionComment: string }
  ) {}
}

export class RejectCourseSuccessAction implements Action {
  readonly type = ActionTypes.REJECT_COURSE_SUCCESS;
}

export class RejectCourseFailureAction implements Action {
  readonly type = ActionTypes.REJECT_COURSE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class PublishCourseAction implements Action {
  readonly type = ActionTypes.PUBLISH_COURSE;
  constructor(public payload: { courseVersionId: number }) {}
}

export class PublishCourseSuccessAction implements Action {
  readonly type = ActionTypes.PUBLISH_COURSE_SUCCESS;
}

export class PublishCourseFailureAction implements Action {
  readonly type = ActionTypes.PUBLISH_COURSE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UnpublishCourseAction implements Action {
  readonly type = ActionTypes.UNPUBLISH_COURSE;
  constructor(public payload: { courseVersionId: number }) {}
}

export class UnpublishCourseSuccessAction implements Action {
  readonly type = ActionTypes.UNPUBLISH_COURSE_SUCCESS;
}

export class UnpublishCourseFailureAction implements Action {
  readonly type = ActionTypes.UNPUBLISH_COURSE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SetDisableNavigationAction implements Action {
  readonly type = ActionTypes.SET_DISABLE_NAVIGATION;
  constructor(public disable: boolean) {}
}

export class GeneratePDFAction implements Action {
  readonly type = ActionTypes.GENERATE_PDF;
  constructor(public payload: { currentCourse: Course }) {}
}

export class GeneratePDFSuccessAction implements Action {
  readonly type = ActionTypes.GENERATE_PDF_SUCCESS;
}

export class GeneratePDFFailureAction implements Action {
  readonly type = ActionTypes.GENERATE_PDF_FAILURE;
}

export type Actions =
  | UnloadCourseAction
  | ChangeCourseDataAction
  | LoadCoursesAction
  | LoadCoursesSuccessAction
  | LoadCoursesFailureAction
  | LoadCourseAction
  | LoadCourseSuccessAction
  | LoadCourseFailureAction
  | LoadCourseHistoryAction
  | LoadCourseHistorySuccessAction
  | LoadCourseHistoryFailureAction
  | SaveCourseAction
  | SaveCourseSuccessAction
  | SaveCourseFailureAction
  | DeleteCourseAction
  | DeleteCourseSuccessAction
  | DeleteCourseFailureAction
  | SearchCoursesAction
  | SearchCoursesSuccessAction
  | SearchCoursesFailureAction
  | ClearCoursesSearchAction
  | CopyCourseAction
  | CopyCourseSuccessAction
  | CopyCourseFailureAction
  | ApprovalRequestAction
  | ApprovalRequestSuccessAction
  | ApprovalRequestFailureAction
  | ApprovalRequestCancelAction
  | UnapproveAction
  | UnapproveSuccessAction
  | UnapproveFailureAction
  | ApprovalRequestCancelSuccessAction
  | ApprovalRequestCancelFailureAction
  | CreateNewCourseVersionAction
  | CreateNewCourseVersionSuccessAction
  | CreateNewCourseVersionFailureAction
  | ResetStateAction
  | ApproveCourseAction
  | ApproveCourseSuccessAction
  | ApproveCourseFailureAction
  | RejectCourseAction
  | RejectCourseSuccessAction
  | RejectCourseFailureAction
  | PublishCourseAction
  | PublishCourseSuccessAction
  | PublishCourseFailureAction
  | UnpublishCourseAction
  | UnpublishCourseSuccessAction
  | UnpublishCourseFailureAction
  | SetDisableNavigationAction
  | GeneratePDFAction
  | GeneratePDFSuccessAction
  | GeneratePDFFailureAction;
