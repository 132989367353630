import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { ICourseModel } from '../../../core/models/course.model';
import { PDFGeneratorService } from '../../../core/services/pdf.service';
import { CourseService } from '../../services/course/course.service';
import { LoadingInActiveAction } from '../core/actions';
import {
  ActionTypes,
  ApprovalRequestAction,
  ApprovalRequestCancelAction,
  ApprovalRequestCancelFailureAction,
  ApprovalRequestCancelSuccessAction,
  ApprovalRequestFailureAction,
  ApprovalRequestSuccessAction,
  ApproveCourseAction,
  ApproveCourseFailureAction,
  ApproveCourseSuccessAction,
  CopyCourseAction,
  CopyCourseFailureAction,
  CopyCourseSuccessAction,
  CreateNewCourseVersionAction,
  CreateNewCourseVersionFailureAction,
  CreateNewCourseVersionSuccessAction,
  DeleteCourseAction,
  DeleteCourseFailureAction,
  DeleteCourseSuccessAction,
  GeneratePDFAction,
  GeneratePDFFailureAction,
  GeneratePDFSuccessAction,
  LoadCourseAction,
  LoadCourseFailureAction,
  LoadCoursesAction,
  LoadCoursesFailureAction,
  LoadCoursesSuccessAction,
  LoadCourseSuccessAction,
  PublishCourseAction,
  PublishCourseFailureAction,
  PublishCourseSuccessAction,
  RejectCourseAction,
  RejectCourseFailureAction,
  RejectCourseSuccessAction,
  SaveCourseAction,
  SaveCourseFailureAction,
  SaveCourseSuccessAction,
  UnapproveAction,
  UnapproveFailureAction,
  UnapproveSuccessAction,
  UnloadCourseAction,
  UnpublishCourseAction,
  UnpublishCourseFailureAction,
  UnpublishCourseSuccessAction,
} from './actions';
import { AssetService } from '../../services/asset/asset.service';

@Injectable()
export class CourseStoreEffects {
  constructor(
    private courseService: CourseService,
    private assetService: AssetService,
    private pdfGeneratorService: PDFGeneratorService,
    private router: Router,
    private actions$: Actions,
  ) {}

  saveCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCourseAction>(ActionTypes.SAVE_COURSE),
      exhaustMap((action) =>
        this.courseService.SaveCourse(action.payload.course).pipe(
          map((res) => {
            return new SaveCourseSuccessAction({
              courseId: res.id || action.payload.course.id,
              mode: action.payload.mode,
            });
          }),
          catchError((error) =>
            observableOf(new SaveCourseFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  copyCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CopyCourseAction>(ActionTypes.COPY_COURSE),
      exhaustMap((action) =>
        this.courseService.CopyCourse(action.payload.course).pipe(
          map(() => new CopyCourseSuccessAction()),
          catchError((error) =>
            observableOf(new CopyCourseFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  copyCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CopyCourseSuccessAction>(ActionTypes.COPY_COURSE_SUCCESS),
      switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        this.router.navigate(['/']);
      }),
    ),
  );

  // Request Approval of Course-Version
  requestApprovalCourseVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApprovalRequestAction>(
        ActionTypes.REQUEST_APPROVAL_COURSE_VERSION,
      ),
      exhaustMap((action) =>
        this.courseService
          .RequestApproval(
            action.payload.course.courseVersionId,
            action.payload.course.id,
            action.payload.course.versionNumber,
          )
          .pipe(
            map(() => new ApprovalRequestSuccessAction()),
            catchError((error) =>
              observableOf(
                new ApprovalRequestFailureAction({ error: error.error }),
              ),
            ),
          ),
      ),
    ),
  );

  requestApprovalCourseVersionSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApprovalRequestSuccessAction>(
        ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_SUCCESS,
      ),
      switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        this.router.navigate(['/']);
      }),
    ),
  );

  requestApprovalCourseVersionFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApprovalRequestFailureAction>(
        ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_FAILURE,
      ),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  cancelApprovalRequestCourseVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApprovalRequestCancelAction>(
        ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_CANCEL,
      ),
      exhaustMap((action) =>
        this.courseService.CancelApproval(action.payload.courseVersionId).pipe(
          map(() => new ApprovalRequestCancelSuccessAction()),
          catchError((error) =>
            observableOf(
              new ApprovalRequestCancelFailureAction({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  cancelApprovalRequestCourseVersionSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApprovalRequestCancelSuccessAction>(
        ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_CANCEL_SUCCESS,
      ),
      switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        this.router.navigate(['/']);
      }),
    ),
  );

  cancelApprovalRequestCourseVersionFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApprovalRequestCancelFailureAction>(
        ActionTypes.REQUEST_APPROVAL_COURSE_VERSION_CANCEL_FAILURE,
      ),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  // unapprove
  unapproveCourseVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnapproveAction>(ActionTypes.UNAPPROVE),
      exhaustMap((action) =>
        this.courseService.Unapprove(action.payload.courseVersionId).pipe(
          map(() => new UnapproveSuccessAction()),
          catchError((error) =>
            observableOf(new UnapproveFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  unapproveCourseVersionSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnapproveSuccessAction>(ActionTypes.UNAPPROVE_SUCCESS),
      switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        this.router.navigate(['/']);
      }),
    ),
  );

  unapproveCourseVersionFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnapproveFailureAction>(ActionTypes.UNAPPROVE_FAILURE),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  // Create new Course-Version
  createNewCourseVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewCourseVersionAction>(
        ActionTypes.CREATE_NEW_COURSE_VERSION,
      ),
      exhaustMap((action) =>
        this.courseService.CreateNewCourseVersion(action.payload.course).pipe(
          map(
            () =>
              new CreateNewCourseVersionSuccessAction({
                newCourseVerion: action.payload.newCourseVerion,
              }),
          ),
          catchError((error) =>
            observableOf(
              new CreateNewCourseVersionFailureAction({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  createNewCourseVersionSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewCourseVersionSuccessAction>(
        ActionTypes.CREATE_NEW_COURSE_VERSION_SUCCESS,
      ),
      tap((action) => {
        const url =
          this.router.url.slice(0, -1) + action.payload.newCourseVerion;
        this.router.navigateByUrl(url).then(() => {
          window.location.reload();
        });
      }),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  saveCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCourseSuccessAction>(ActionTypes.SAVE_COURSE_SUCCESS),
      exhaustMap((action) => {
        return [new LoadCoursesAction({ mode: action.payload.mode })];
      }),
      tap(() => {
        if (this.router.url.includes('create')) {
          this.router.navigate(['/']);
        }
      }),
    ),
  );

  // Putting 'LoadingInActiveAction' in the above effect does not work, for wathever reason...
  saveCourseSuccessEffect2$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCourseSuccessAction>(ActionTypes.SAVE_COURSE_SUCCESS),
      map(() => new LoadingInActiveAction()),
    ),
  );

  copyCourseSuccessEffect2$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CopyCourseSuccessAction>(ActionTypes.COPY_COURSE_SUCCESS),
      map(() => new LoadingInActiveAction()),
    ),
  );

  saveCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCourseFailureAction>(ActionTypes.SAVE_COURSE_FAILURE),
      map(() => new LoadingInActiveAction()),
    ),
  );

  loadCoursesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCoursesAction>(ActionTypes.LOAD_COURSES_DATA),
      exhaustMap((action) =>
        this.courseService.GetCoursesByUser(action.payload.mode).pipe(
          map(
            (transformedCourses) =>
              new LoadCoursesSuccessAction({ courses: transformedCourses }),
          ),
          catchError((error) =>
            observableOf(new LoadCoursesFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  loadCoursesSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCoursesSuccessAction>(ActionTypes.LOAD_COURSES_DATA_SUCCESS),
      map(() => new LoadingInActiveAction()),
    ),
  );

  loadCoursesFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCoursesFailureAction>(ActionTypes.LOAD_COURSES_DATA_FAILURE),
      map(() => new LoadingInActiveAction()),
    ),
  );

  loadCurrentCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCourseAction>(ActionTypes.LOAD_COURSE_DATA),
      exhaustMap((action) =>
        this.courseService
          .GetCourseById(action.payload.id, action.payload.versionNumber)
          .pipe(
            map((course: ICourseModel) => {
              if (
                course &&
                course.categoryId &&
                course.subcategoryId &&
                course.title &&
                course.externalUrl !== undefined &&
                course.author
              ) {
                return new LoadCourseSuccessAction({
                  courseId: action.payload.id,
                  versionNumber: action.payload.versionNumber,
                  courseVersionId: course.courseVersionId,
                  approvalRequested: course.approvalRequested,
                  rejected: course.rejected,
                  approved: course.approved,
                  published: course.published,
                  slides: course.slides,
                  categoryId: course.categoryId,
                  subcategoryId: course.subcategoryId,
                  title: course.title,
                  externalUrl: course.externalUrl,
                  author: course.author,
                });
              }

              return new LoadCourseFailureAction({
                error: 'Course data is incomplete',
              });
            }),
            catchError((error) => {
              return observableOf(
                new LoadCourseFailureAction({ error: error.error }),
              );
            }),
          ),
      ),
    ),
  );

  loadCurrentCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCourseSuccessAction>(ActionTypes.LOAD_COURSE_DATA_SUCCESS),
      map(() => new LoadingInActiveAction()),
    ),
  );

  deleteCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCourseAction>(ActionTypes.DELETE_COURSE),
      exhaustMap((action) =>
        this.courseService.DeleteCourse(action.payload.id).pipe(
          map(() => new DeleteCourseSuccessAction()),
          catchError((error) =>
            observableOf(new DeleteCourseFailureAction({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  deleteCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCourseSuccessAction>(ActionTypes.DELETE_COURSE_SUCCESS),
      switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        this.router.navigate(['/']);
      }),
    ),
  );

  deleteCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCourseFailureAction>(ActionTypes.DELETE_COURSE_FAILURE),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  rejectCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RejectCourseAction>(ActionTypes.REJECT_COURSE),
      exhaustMap((action) =>
        this.courseService
          .RejectApproval(
            action.payload.courseVersionId,
            action.payload.rejectionComment,
          )
          .pipe(
            map(() => new RejectCourseSuccessAction()),
            catchError((error) =>
              observableOf(
                new RejectCourseFailureAction({ error: error.error }),
              ),
            ),
          ),
      ),
    ),
  );

  rejectCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RejectCourseSuccessAction>(ActionTypes.REJECT_COURSE_SUCCESS),
      switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        location.reload();
        // TODO
        // this.router.navigate(['/course-list']);
      }),
    ),
  );

  rejectCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RejectCourseFailureAction>(ActionTypes.REJECT_COURSE_FAILURE),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  approveCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveCourseAction>(ActionTypes.APPROVE_COURSE),
      exhaustMap((action) =>
        this.courseService.ApproveRequest(action.payload.courseVersionId).pipe(
          map(() => new ApproveCourseSuccessAction()),
          catchError((error) =>
            observableOf(
              new ApproveCourseFailureAction({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  approveCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveCourseSuccessAction>(ActionTypes.APPROVE_COURSE_SUCCESS),
      switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        location.reload();
        // TODO
        // this.router.navigate(['/course-list']);
      }),
    ),
  );

  approveCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveCourseFailureAction>(ActionTypes.APPROVE_COURSE_FAILURE),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  // Publish
  publishCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PublishCourseAction>(ActionTypes.PUBLISH_COURSE),
      exhaustMap((action) =>
        this.courseService.Publish(action.payload.courseVersionId).pipe(
          map(() => new PublishCourseSuccessAction()),
          catchError((error) =>
            observableOf(
              new PublishCourseFailureAction({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  publishCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PublishCourseSuccessAction>(ActionTypes.PUBLISH_COURSE_SUCCESS),
      exhaustMap(() => [new LoadingInActiveAction()]),
      // switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        this.router.navigate(['/']);
      }),
    ),
  );

  publishCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PublishCourseFailureAction>(ActionTypes.PUBLISH_COURSE_FAILURE),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  // Unpublish
  unpublishCourseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnpublishCourseAction>(ActionTypes.UNPUBLISH_COURSE),
      exhaustMap((action) =>
        this.courseService.Unpublish(action.payload.courseVersionId).pipe(
          map(() => new UnpublishCourseSuccessAction()),
          catchError((error) =>
            observableOf(
              new UnpublishCourseFailureAction({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  unpublishCourseSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnpublishCourseSuccessAction>(
        ActionTypes.UNPUBLISH_COURSE_SUCCESS,
      ),
      exhaustMap(() => [new LoadingInActiveAction()]),
      // switchMap(() => of(new UnloadCourseAction())),
      tap(() => {
        this.router.navigate(['/']);
      }),
    ),
  );

  unpublishCourseFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnpublishCourseFailureAction>(
        ActionTypes.UNPUBLISH_COURSE_FAILURE,
      ),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  generatePDFEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GeneratePDFAction>(ActionTypes.GENERATE_PDF),
      exhaustMap((action: GeneratePDFAction) =>
        this.pdfGeneratorService
          .generate(
            action.payload.currentCourse.slides,
            action.payload.currentCourse.title,
          )
          .pipe(
            map(() => new GeneratePDFSuccessAction()),
            catchError(() => observableOf(new GeneratePDFFailureAction())),
          ),
      ),
    ),
  );

  generatePDFSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GeneratePDFSuccessAction>(ActionTypes.GENERATE_PDF_SUCCESS),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );

  generatePDFFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GeneratePDFFailureAction>(ActionTypes.GENERATE_PDF_FAILURE),
      exhaustMap(() => [new LoadingInActiveAction()]),
    ),
  );
}
