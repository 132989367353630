export const environment = {
  production: true,
  useAuth: true,
  applicationUrl: 'http://dev.myreishauer.com',
  backendApiUrl: 'https://func-elearning-dev.azurewebsites.net/api',
  apiKey: '5-Cpm1aARLkjMOvW-pfvb1H4jo7HMeZamyVhuZTaNr5fAzFuqS7BpQ==', // TODO: Add API key
  domain: 'reishauer-dev.eu.auth0.com',
  audience: 'https://reishauer-dev.eu.auth0.com/api/v2/',
  clientId: 'KMXTrQMgxmhw292EtrKdz9GrVQv2Sx3F',
  redirectUri: window.location.origin,
  appInsights: {
    instrumentationKey: '2befc4ef-853c-4df3-aa58-3257dcb8526c', // TODO: Add instrumentation key
  },
  mockBackendServices: 'no',
};
