import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor(private readonly authService: AuthService) {
    navigator.serviceWorker.addEventListener('message', async (event) => {
      if (event.data === 'requestToken') {
        const token = await this.getToken();
        event.ports[0].postMessage(token);
      }
    });
  }

  private async getToken() {
    return await lastValueFrom(
      this.authService.getAccessTokenSilently({ cacheMode: 'cache-only' }),
    );
  }
}
