import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthStoreModule } from './store/auth';
import { CategoryStoreModule } from './store/category';
import { CoreStoreModule } from './store/core';
import { CourseStoreModule } from './store/course';
import { PermissionsStoreModule } from './store/permissions';
import { SlideStoreModule } from './store/slide';
import { UserStoreModule } from './store/user';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { MediaStoreModule } from './store/media';

@NgModule({
  imports: [
    CommonModule,
    CoreStoreModule,
    AuthStoreModule,
    PermissionsStoreModule,
    UserStoreModule,
    CategoryStoreModule,
    CourseStoreModule,
    SlideStoreModule,
    MediaStoreModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true,
    }),
    TranslateModule.forChild({
      extend: true,
    }),
  ],
})
export class SharedModule {}
