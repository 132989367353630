<div class="relative">
  <button
    [ngClass]="
      showLanguageDropdown
        ? 'bg-[#808fad] text-white rounded-t-md justify-end'
        : 'rounded-md bg-white text-[#2c4476] justify-between'
    "
    class="h-10 w-full px-4 flex items-center"
    (click)="toggleLanguageDropdown()"
  >
    <p *ngIf="!showLanguageDropdown">{{ selectedLanguage.name }}</p>
    <img
      *ngIf="!showLanguageDropdown"
      src="/assets/icons/Reishauer_Icons-Down.svg"
      alt="Arrow down"
      class="w-4"
    />
    <img
      *ngIf="showLanguageDropdown"
      src="/assets/icons/Reishauer_Icons-Up.svg"
      alt="Arrow up"
      class="w-4"
    />
  </button>
  <div
    *ngIf="showLanguageDropdown"
    class="absolute top-10 flex flex-col w-full"
  >
    <button
      *ngFor="let language of languages"
      [ngClass]="
        language.code === selectedLanguageKey
          ? 'bg-white text-[#2c4476]'
          : 'bg-[#808fad] text-white'
      "
      class="h-10 w-full px-4 flex items-center justify-start last:rounded-b-md"
      (click)="changeLanguage(language)"
    >
      {{ language.name }}
    </button>
  </div>
</div>
