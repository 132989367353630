<div class="approval-controls-container">
  <rh-button
    *ngIf="!publishMode"
    text="header.approve"
    [imageUrl]="'/assets/img/buttons/confirm.svg'"
    (click)="requestApproval()"
  ></rh-button>
  <rh-button
    *ngIf="!publishMode"
    text="header.reject"
    [imageUrl]="'/assets/img/buttons/cancel.svg'"
    (click)="requestRejection()"
    [redMode]="true"
  ></rh-button>
</div>
