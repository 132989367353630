<div
  [ngClass]="{
    'text-[18px] ': isSmall,
    'text-[16px]': !isSmall
  }"
  class="font-brand-regular z-20"
>
  <div
    class="flex flex-col w-full cursor-pointer pt-2"
    [ngClass]="{
      'bg-brand-blue-700 text-white': category.selected,
      'bg-brand-blue-550 text-[#a9b5d1] hover:bg-brand-blue-700': !category.selected,
      'px-small': isSmall,
      'px-big': !isSmall,
    }"
    (click)="toggleDropdown(); scrollIntoView('cat-' + category.id)"
  >
    <div class="w-full h-11 flex flex-row gap-10 justify-between items-center">
      <div>
        {{ category.name }}
      </div>
      <div>
        <img
          *ngIf="!category.toggled"
          src="/assets/img/buttons/Reishauer_Icons-SubMenuDown.svg"
          class="w-5 h-5"
        />
        <img
          *ngIf="category.toggled"
          src="/assets/img/buttons/Reishauer_Icons-SubMenuUp.svg"
          class="w-5 h-5"
        />
      </div>
    </div>
  </div>
  <div
    *ngIf="
      category.toggled &&
      category.subcategories &&
      category.subcategories.length > 0
    "
  >
    <div
      *ngFor="let subcategory of category.subcategories"
      [ngClass]="{
        'pl-small': isSmall,
        'pl-big': !isSmall,
        }"
      class="bg-brand-blue-700 hover:bg-brand-blue-750 text-white"
    >
      <div
        class="w-full pl-4 flex flex-row py-2 gap-2"
        (click)="scrollIntoView('sub-' + subcategory.id)"
      >
        <div>
          {{ subcategory.name }}
        </div>
      </div>
    </div>
  </div>
</div>
