import { Component, Input } from '@angular/core';

@Component({
  selector: 'rh-confirm-cancel-header',
  templateUrl: './confirm-cancel-header.component.html',
  styleUrls: ['./confirm-cancel-header.component.scss'],
})
export class ConfirmCancelHeaderComponent {
  @Input() public title!: string;
  @Input() public text!: string;
}
