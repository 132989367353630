import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rh-confirm-cancel-comment-field',
  templateUrl: './confirm-cancel-comment-field.component.html',
  styleUrls: ['./confirm-cancel-comment-field.component.scss'],
})
export class ConfirmCancelCommentFieldComponent {
  private _text = '';
  @Input()
  public get text(): string {
    return this._text;
  }
  public set text(val: string) {
    this._text = val;
    this.textChange?.emit(this._text);
  }

  @Output() public textChange: EventEmitter<string> =
    new EventEmitter<string>();
}
