import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAssetDto } from '../../../../../core/dto/asset-dto.model';
import { IDynamicComponent } from '../../../../../core/interfaces/dynamic-component.interface';

@Component({
  selector: 'rh-base-template',
  template: '',
})
export abstract class TemplateBaseComponent implements IDynamicComponent {
  public editMode = false;
  public zoomed = false;
  public static templateThumbnailUrl: string;
  public static templateId = 0;
  public abstract onActivated(): void;

  // Use the following event in derived classes if you need to -> will be fired by the slide-template-container...
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onEditModeChanging(): void {}

  private _assets: IAssetDto[] = [];
  @Input()
  public get assets(): IAssetDto[] {
    return this._assets;
  }
  public set assets(val: IAssetDto[]) {
    this._assets = val;
    this.mapAssets();
  }

  @Output()
  public assetChange: EventEmitter<IAssetDto[]> = new EventEmitter<
    IAssetDto[]
  >();

  // Each template has its own definition of assets -> map them in the concrete implementation
  abstract mapAssets(): void;

  public findAsset(placeholderCode: string): IAssetDto {
    return this.assets.filter((a) => a.placeholderCode === placeholderCode)[0];
  }

  public createAsset(placeholderCode: string): IAssetDto {
    return { placeholderCode: placeholderCode } as IAssetDto;
  }

  public ensureAsset(placeholderCode: string) {
    const existingAsset = this.findAsset(placeholderCode);

    if (existingAsset) {
      return existingAsset;
    }

    return this.createAsset(placeholderCode);
  }

  //
  protected handleAssetChange(assetContent: string, placeholderCode: string) {
    if (this.assetChange) {
      const change = this.ensureAsset(placeholderCode);
      change.content = assetContent;
      this.assetChange.emit([change]);
    }
  }
}
