<div
  class="media-tile-container relative block cursor-pointer w-full aspect-video p-[30px] shadow-xl rounded-md ease-in-out duration-300"
  [title]="title"
>
  <!-- <div *ngIf="!assetLoaded && isVideo" class="loading-background">
    <img src="./assets/icons/Icon_Loading.svg" alt="Loading" />
  </div> -->
  <div class="overlay"></div>
  <div
    *ngIf="!isVideo"
    class="image-container absolute top-0 left-0 w-full h-full bg-cover rounded-md"
    [ngStyle]="{
      'background-image':
        'linear-gradient(to right bottom, rgba(64,92,154, 1), rgba(64,92,154, .65) 45%, rgba(64,92,154, .0) 70%, rgba(64,92,154, 0.0) 100%), ' +
        'url(' + url + ')'
    }"
  ></div>
  <div
    *ngIf="isVideo"
    class="video-container absolute top-0 left-0 w-full h-full bg-cover rounded-md overflow-hidden"
  >
    <video
      #videoElement
      [controls]="false"
      crossorigin="anonymous"
      class="video-element"
      preload="auto"
      [autoplay]="false"
      [loop]="false"
      type="video/mp4"
      [src]="url"
      [style.objectFit]="'cover'"
    ></video>
  </div>

  <div class="title-container">
    <div class="title">{{ title }}</div>
    <div class="details-container">
      <div class="details-row">
        <div class="col col-1">Kategorie</div>
        <div class="col">{{ category }}</div>
      </div>
      <div class="details-row">
        <div class="col col-1">Autor</div>
        <div class="col">{{ author }}</div>
      </div>
      <div class="details-row">
        <div class="col col-1">Upload</div>
        <div class="col">{{ uploaded | date }}</div>
      </div>
    </div>
  </div>

  <div class="icons">
    <img *ngIf="isVideo" src="./assets/icons/Icon_Film_Weiss.svg" alt="Video" />
    <img
      *ngIf="!isVideo"
      src="./assets/icons/Icon_Bild_Weiss.svg"
      alt="Image"
    />
  </div>

  <div class="buttons">
    <rh-fab
      *ngIf="private"
      class="icon"
      [imageUrl]="'./assets/icons/Reishauer_Icon-Private.svg'"
      title="{{ 'buttons.private' | translate }}"
      (click)="$event.stopPropagation()"
    ></rh-fab>
    <rh-fab
      *ngIf="showDeleteButton"
      class="button"
      [imageUrl]="'./assets/img/buttons/Reishauer_Icon_DeleteWhite.svg'"
      (click)="delete($event)"
      title="{{ 'buttons.delete' | translate }}"
    ></rh-fab>
    <rh-fab
      class="button"
      [imageUrl]="'./assets/img/buttons/Reishauer_Icon-Edit.svg'"
      (click)="edit($event)"
      title="{{ 'buttons.edit' | translate }}"
    ></rh-fab>
    <rh-fab
      class="button"
      [imageUrl]="'./assets/img/buttons/Reishauer_Icon-View.svg'"
      (click)="view($event)"
      title="{{ 'buttons.view' | translate }}"
    ></rh-fab>
  </div>
</div>
