import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IMediaBlobAssetsDto } from '../../../../core/dto/media-blob-assets-dto.model';
import { LoadingActiveAction } from '../../../../shared/store/core/actions';
import { SaveTemporaryMediaAssetAction } from '../../../../shared/store/media/actions';
import { State } from '../../../../shared/store/state';

@Component({
  selector: 'rh-media-drag-and-drop-area',
  templateUrl: './media-drag-and-drop-area.component.html',
  styleUrls: ['./media-drag-and-drop-area.component.scss'],
})
export class MediaDragAndDropAreaComponent implements OnInit {
  public active = false;
  public loadingOrSaving = true;

  @ViewChild('multiFileUpload') public multiFileUpload!: ElementRef;

  private loadingOrSaving$!: Observable<boolean>;
  private subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<State>) {}

  public async ngOnInit(): Promise<void> {
    this.loadingOrSaving$ = this.store$.select((state) => {
      return state.Core.loadingOrSaving;
    });

    this.subscriptions.add(
      this.loadingOrSaving$?.subscribe((state: boolean) => {
        console.log('state', state);
        this.loadingOrSaving = state;
      })
    );
  }

  public async onStatusChanged(status: boolean) {
    this.active = status;
  }

  public async onFilesDropped(evt: FileList): Promise<void> {
    this.readMediaFiles(evt);
  }

  public async selectFiles() {
    this.multiFileUpload?.nativeElement?.click();
  }

  public async onFilesSelected(evt: Event): Promise<void> {
    const inputElement = evt.target as HTMLInputElement;
    if (inputElement?.files && inputElement.files.length > 0) {
      this.readMediaFiles(inputElement.files);
    }
  }

  private readMediaFiles(files: FileList): void {
    this.upload(files);
  }

  // Progress bar
  upload(files: FileList) {
    this.store$.dispatch(new LoadingActiveAction());

    this.store$.dispatch(
      new SaveTemporaryMediaAssetAction({
        asset: {
          files: Array.from(files),
        } as IMediaBlobAssetsDto,
      })
    );
  }
}
