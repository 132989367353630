import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import {
  ActionTypes,
  LoadPermissionsAction,
  LoadPermissionsFailureAction,
  LoadPermissionsSuccessAction,
} from './actions';

@Injectable()
export class PermissionsStoreEffects {
  constructor(
    private permissionsService: PermissionsService,
    private actions$: Actions
  ) {}

  loadPermissionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadPermissionsAction>(ActionTypes.LOAD_PERMISSIONS),
      exhaustMap(() =>
        this.permissionsService.LoadPermissions().pipe(
          tap((x) => {
            console.log('loaded permissions:', x);
          }),
          map(
            (permissions) =>
              new LoadPermissionsSuccessAction({
                permissions: permissions,
              })
          ),
          catchError((error) =>
            observableOf(
              new LoadPermissionsFailureAction({ error: error.error })
            )
          )
        )
      )
    )
  );
}
