import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { AuthenticateService } from '../../../core/services/auth/auth.service';
import { ActionTypes, LogOutUserAction } from './actions';

@Injectable()
export class AuthStoreEffects {
  constructor(
    private translate: TranslateService,
    private authService: AuthenticateService,
    private actions$: Actions
  ) {}

  logoutUserEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LogOutUserAction>(ActionTypes.LOGOUT_USER),
        tap(() => {
          this.authService.logout();
        })
      ),
    { dispatch: false }
  );
}
