import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DIALOG_DATA } from '../../../core/services/dialog/dialog-tokens';
import { SlidesDialogRef } from '../../../core/services/dialog/slides-dialog-ref';
import { IConfirmCancelDialogData } from './core/confirm-cancel-dialog-data.interface';
import { IConfirmationResult } from './core/confirmation-result.model';

@Component({
  selector: 'rh-confirm-cancel-dialog',
  templateUrl: './confirm-cancel-dialog.component.html',
  styleUrls: ['./confirm-cancel-dialog.component.scss'],
})
export class ConfirmCancelDialogComponent {
  @Input() public title!: string;
  @Input() public text!: string;
  @Input() public requireComment!: boolean;
  @Input() public confirmationButtonText!: string;
  @Input() public comment = '';

  @Output() public confirmChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public get confirmButtonEnabled(): boolean {
    return !this.requireComment || (!!this.comment && this.comment.length > 0);
  }

  constructor(
    @Inject(DIALOG_DATA) public data: IConfirmCancelDialogData,
    private dialogRef: SlidesDialogRef,
  ) {
    if (data) {
      this.title = data.title;
      this.text = data.text;
      this.confirmationButtonText = data.confirmationButtonText;
      this.requireComment = data.requireComment;
    }
  }

  public handleEvent(val: boolean) {
    this.dialogRef.close({
      decision: val,
      comment: this.comment,
    } as IConfirmationResult);
  }
}
