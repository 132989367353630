import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Category } from '../../../../shared/models/category.model';
import { IMediaTile } from '../media-tile/media-tile.model';

@Component({
  selector: 'rh-media-metadata-viewer',
  templateUrl: './media-metadata-viewer.component.html',
  styleUrls: ['./media-metadata-viewer.component.scss'],
})
export class MediaMetadataViewerComponent {
  @Input()
  public editMode = false;

  @Input()
  public categories?: Category[];

  private _tiles: IMediaTile[] = [];
  @Input()
  public get tiles(): IMediaTile[] {
    return this._tiles;
  }

  public set tiles(value: IMediaTile[]) {
    this._tiles = value;
    this.currentTileIndex = 0;
    if (value.length) {
      this.currentTile = this._tiles[0];
      this.ensureCategory(this._tiles);
    }
  }

  // Form fields
  public currentTile?: IMediaTile;
  public currentTileIndex = 0;

  @Output()
  public mediaDeclined: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public confirmationDone: EventEmitter<IMediaTile[]> = new EventEmitter<
    IMediaTile[]
  >();

  public get isVideo(): boolean {
    return (
      (this.currentTile &&
        this.currentTile.imageUrl &&
        this.currentTile.imageUrl.indexOf('.mp4') > 0) ||
      false
    );
  }

  public get isValid(): boolean {
    return (
      !!this.currentTile &&
      !!this.currentTile.title &&
      this.currentTile.title.length > 0 &&
      this.currentTile.categoryId > 0
    );
  }

  public async onCategoryChanged(category: Category) {
    if (!this.currentTile) {
      return;
    }
    localStorage.setItem(
      'last-category',
      JSON.stringify({ id: category.id, name: category.name })
    );
    this.currentTile.categoryId = category.id;
  }

  public async dismissAsset(tile: IMediaTile) {
    if (!tile.imageUrl) {
      return;
    }

    this.mediaDeclined?.emit(tile.id);

    if (!this.moveNext(false, tile.imageUrl)) {
      return;
    }

    this.confirmationDone?.emit(this.tiles.filter((f) => f.selected));
  }

  public async confirmAsset(url?: string) {
    if (!this.moveNext(true, url)) {
      return;
    }

    this.confirmationDone?.emit(this.tiles.filter((f) => f.selected));
  }

  public async cancel() {
    this.confirmationDone?.emit([]);
  }

  public async save(tile: IMediaTile) {
    this.confirmationDone?.emit([tile]);
  }

  //
  private moveNext(select: boolean, url?: string) {
    if (!url || !this.currentTile) {
      return true;
    }

    this.currentTile.selected = select;

    if (this.currentTileIndex < this.tiles.length - 1) {
      this.currentTile = this.tiles[++this.currentTileIndex];
      return false;
    }

    this.currentTile = undefined;
    this.currentTileIndex = 0;

    return true;
  }

  private ensureCategory(mediaTiles: IMediaTile[]) {
    // If the tile does not have a category, we will use the last one the user selected by design
    const lastCategory = localStorage.getItem('last-category');
    if (!lastCategory) {
      return;
    }

    const parsedCategory = JSON.parse(lastCategory) as Category;

    for (let i = 0; i < mediaTiles.length; i++) {
      const mediaTile = mediaTiles[i];

      if (!mediaTile.category) {
        mediaTile.category = parsedCategory;
        mediaTile.categoryId = mediaTile.category.id;
        mediaTile.categoryName = mediaTile.category.name;
      }
    }
  }
}
