<div *ngIf="currentTile" class="media-metadata-viewer-container">
  <div class="header">
    <div class="empty"></div>
    <div class="centered-item">
      <img class="img" src="/assets/img/buttons/Reishauer_Icon-Upload.svg" />
      <div *ngIf="!editMode">{{ 'media.upload.title' | translate }}</div>
      <div *ngIf="editMode">{{ 'media.edit.title' | translate }}</div>
    </div>
    <div class="tiles-count">
      <div *ngIf="!editMode">
        {{ currentTileIndex + 1 }} / {{ tiles.length }}
      </div>
    </div>
  </div>

  <div class="content">
    <div class="preview">
      <div
        *ngIf="!isVideo"
        class="image"
        [ngStyle]="{
          'background-image': 'url(' + currentTile.displayImageUrl + ')'
        }"
      ></div>
      <video
        *ngIf="isVideo"
        #videoElement
        [controls]="false"
        crossorigin="anonymous"
        class="placeholder-object video-element"
        preload="auto"
        [autoplay]="false"
        [loop]="false"
        type="video/mp4"
        [src]="currentTile.displayImageUrl"
        [style.object-fit]="'cover'"
      ></video>
    </div>
    <div class="details">
      <div class="grid">
        <div class="item">
          {{ 'media.metadataEditor.name' | translate }}
        </div>
        <div class="item">
          <rh-textbox [(value)]="currentTile.title"></rh-textbox>
        </div>
        <div class="item">
          {{ 'media.metadataEditor.category' | translate }}
        </div>
        <div class="item">
          <rh-dropdown
            [items]="categories"
            [(model)]="currentTile.category"
            (modelChange)="onCategoryChanged($event)"
          ></rh-dropdown>
        </div>
        <div class="item">
          {{ 'media.metadataEditor.author' | translate }}
        </div>
        <div class="item">
          <rh-textbox
            [(value)]="currentTile.author"
            [disabled]="true"
          ></rh-textbox>
        </div>
        <div class="item">
          {{ 'media.metadataEditor.uploaded' | translate }}
        </div>
        <div class="item">
          <rh-textbox
            [value]="(currentTile.uploaded | date : 'short') || undefined"
            [disabled]="true"
          ></rh-textbox>
        </div>
        <div class="item">
          {{ 'media.metadataEditor.private' | translate }}
        </div>
        <div class="item">
          <rh-checkbox [(value)]="currentTile.private"></rh-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="toolbar">
    <rh-button
      *ngIf="!editMode"
      text="Dismiss"
      [textColor]="'#DF433F'"
      [naturalMode]="true"
      imageUrl="/assets/icons/Reishauer_Icon-Dismiss.svg"
      (buttonClick)="dismissAsset(currentTile)"
    ></rh-button>
    <rh-button
      *ngIf="!editMode"
      text="Confirm"
      [textColor]="'#7EAE39'"
      [naturalMode]="true"
      [disabled]="!isValid"
      imageUrl="/assets/icons/Reishauer_Icon-Confirm.svg"
      (buttonClick)="confirmAsset(currentTile.imageUrl)"
    ></rh-button>
    <rh-button
      *ngIf="editMode"
      text="Cancel"
      [textColor]="'#DF433F'"
      [naturalMode]="true"
      imageUrl="/assets/icons/Reishauer_Icon-Dismiss.svg"
      (buttonClick)="cancel()"
    ></rh-button>
    <rh-button
      *ngIf="editMode"
      text="Save"
      [textColor]="'#7EAE39'"
      [naturalMode]="true"
      [disabled]="!isValid"
      imageUrl="/assets/icons/Reishauer_Icon-Confirm.svg"
      (buttonClick)="save(currentTile)"
    ></rh-button>
  </div>
</div>
