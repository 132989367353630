<div class="full-width-text-template-container top-bottom-padding-template">
  <div class="content-container">
    <rh-plaintext-placeholder
      class="main-title"
      [fontSize]="'var(--font-size-title-smaller)'"
      [lineHeight]="'1em'"
      [fontColor]="'#254FAA'"
      [text]="title"
      [editMode]="editMode"
      (textChange)="onTitleChange($event)"
    ></rh-plaintext-placeholder>
    <rh-rich-text-placeholder
      class="editor"
      [html]="html"
      [editMode]="editMode"
      (contentChange)="onContentChange($event)"
    >
    </rh-rich-text-placeholder>
  </div>
</div>
