import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Category } from '../../../shared/models/category.model';

@Component({
  selector: 'rh-vertical-menu-dropdown',
  templateUrl: './vertical-menu-dropdown.component.html',
  styleUrls: ['./vertical-menu-dropdown.component.scss'],
})
export class VerticalMenuDropdownComponent {
  @Input() category!: Category;
  @Input() isSmall = false;
  @Output() toggle = new EventEmitter<void>();
  @Output() selectedId = new EventEmitter<string>();

  scrollIntoView(selectedCategory: string) {
    this.selectedId.emit(selectedCategory);
  }

  toggleDropdown() {
    this.toggle.emit();
  }
}
