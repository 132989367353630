<div
  *ngIf="!editMode"
  class="plaintext-placeholder-container"
  [ngStyle]="{
    fontFamily: fontFamily,
    color: fontColor,
    fontSize: fontSize,
    lineHeight: lineHeightString,
    textAlign: textAlign,
    textTransform: textTransform
  }"
>
  {{ text }}
</div>
<textarea
  *ngIf="editMode"
  rows="1"
  #textArea
  spellcheck="false"
  class="plaintext-placeholder-container textarea-placeholder"
  [ngStyle]="{
    fontFamily: fontFamily,
    color: fontColor,
    fontSize: fontSize,
    lineHeight: lineHeightString,
    textAlign: textAlign,
    backgroundColor: editModeBackgroundColor,
    textTransform: uppercase ? 'uppercase' : 'none'
  }"
  [(ngModel)]="text"
  (ngModelChange)="onTextChange()"
></textarea>
