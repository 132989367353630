<div
  *ngIf="username !== undefined"
  [ngClass]="
    showUserDropdown
      ? 'bg-brand-blue-550 text-white rounded-t-md'
      : 'text-brand-blue-400'
  "
  class="flex items-center z-10 relative h-10 p-2 text-[13px]"
>
  <button
    type="button"
    class="flex w-full items-center gap-2 grow"
    (click)="toggleUserDropdown()"
  >
    <img src="/assets/icons/Reishauer_Icons-User.svg" class="w-5 h-5" />

    <span class="whitespace-nowrap text-[13px] font-brand-regular">
      {{ company }} | {{ username }}
    </span>
    <img
      *ngIf="showUserDropdown"
      src="/assets/icons/Reishauer_Icons-Close.svg"
      class="w-4 h-4 ml-2"
    />
  </button>

  <div
    *ngIf="showUserDropdown"
    class="absolute top-10 flex right-0 w-full h-fit items-center origin-top-right text-white rounded-b-md bg-brand-blue-550 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
  >
    <a
      routerLink=""
      (click)="logout()"
      class="px-4 py-2 h-10 items-center w-full text-white hover:bg-brand-blue-510 rounded-b-md cursor-pointer"
    >
      {{ 'header.signOut' | translate }}
    </a>
  </div>
</div>
