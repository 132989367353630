import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SYNC_CATEGORIES: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.SYNC_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    }
    case ActionTypes.SYNC_CATEGORIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_CATEGORY: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    }
    case ActionTypes.DELETE_CATEGORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_CATEGORIES: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        categories: action.payload.categories,
      };
    }
    case ActionTypes.LOAD_CATEGORIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.SAVE_CATEGORY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypes.SAVE_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ActionTypes.SAVE_CATEGORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_SUBCATEGORY: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.DELETE_SUBCATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    }
    case ActionTypes.DELETE_SUBCATEGORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.SET_SELECTED_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.selectedCategory,
        sidebarCategory: action.selectedCategory,
      };
    }
    case ActionTypes.SET_SIDEBAR_CATEGORY: {
      return {
        ...state,
        sidebarCategory: action.sidebarCategory,
      };
    }
    default: {
      return state;
    }
  }
}
