import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IPermissionModel } from '../../models/permission.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private http: HttpClient) {}

  LoadPermissions(): Observable<IPermissionModel> {
    return this.http
      .get<IPermissionModel>(
        `${environment.backendApiUrl}/GetPermissions?code=${environment.apiKey}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }
}
