import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SET_MODE: {
      return {
        ...state,
        mode: action.payload.mode,
      };
    }
    case ActionTypes.SET_PREVIEW_MODE_ON: {
      return {
        ...state,
        isPreviewMode: true,
      };
    }
    case ActionTypes.SET_PREVIEW_MODE_OFF: {
      return {
        ...state,
        isPreviewMode: false,
      };
    }
    case ActionTypes.TOGGLE_PREVIEW_MODE: {
      return {
        ...state,
        isPreviewMode: !state.isPreviewMode,
      };
    }
    default: {
      return state;
    }
  }
}
