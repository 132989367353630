<div class="cover-page-template-container">
  <div class="content-container">
    <div class="inner-container">
      <div class="section-container">
        <rh-plaintext-placeholder
          class="main-title"
          [fontSize]="'var(--font-size-medium-title)'"
          [lineHeight]="'1em'"
          [fontColor]="'#FFFFFF'"
          [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
          [text]="title"
          [editMode]="editMode"
          (textChange)="onTitleChange($event)"
        ></rh-plaintext-placeholder>

        <rh-plaintext-placeholder
          class="sub-title"
          [fontSize]="'var(--font-size-small-title)'"
          [lineHeight]="'1.5em'"
          [fontColor]="'#a2bef3'"
          [text]="subTitle"
          [editMode]="editMode"
          [uppercase]="false"
          (textChange)="onSubTitleChange($event)"
        ></rh-plaintext-placeholder>
      </div>
      <rh-plaintext-placeholder
        class="cover-page-number"
        [fontSize]="'var(--font-size-large-number)'"
        [lineHeight]="'1em'"
        [fontColor]="'#5C78B5'"
        [text]="number"
        [editMode]="editMode"
        [textAlign]="'right'"
        (textChange)="onNumberChange($event)"
      ></rh-plaintext-placeholder>

      <rh-image-placeholder
        class="image-placeholder"
        [showToolbar]="true"
        [showZoom]="false"
        [url]="url"
        [objectFit]="'cover'"
        [editMode]="editMode"
        (zoomedChange)="onZoomedChanged($event)"
        (urlChange)="onUrlChange($event)"
        [overlayMode]="overlayMode"
      ></rh-image-placeholder>
    </div>
  </div>
  <div *ngIf="zoomed" class="zoom-backdrop"></div>
</div>
