import { Action } from '@ngrx/store';
import { Category } from '../../models/category.model';

export enum ActionTypes {
  SYNC_CATEGORIES = '[Category] Sync Categories',
  SYNC_CATEGORIES_SUCCESS = '[Category] Sync Categories Success',
  SYNC_CATEGORIES_FAILURE = '[Category] Sync Categories Failure',
  LOAD_CATEGORIES = '[Category] Load Categories Data',
  LOAD_CATEGORIES_SUCCESS = '[Category] Load Categories Success',
  LOAD_CATEGORIES_FAILURE = '[Category] Load Categories Failure',
  SAVE_CATEGORY = '[Category] Save Category',
  SAVE_CATEGORY_SUCCESS = '[Category] Save Category Success',
  SAVE_CATEGORY_FAILURE = '[Category] Save Category Failure',
  DELETE_CATEGORY = '[Category] Delete Category',
  DELETE_CATEGORY_SUCCESS = '[Category] Delete Category Success',
  DELETE_CATEGORY_FAILURE = '[Category] Delete Category Failure',
  SAVE_SUBCATEGORY = '[Subcategory] Save Subcategory',
  SAVE_SUBCATEGORY_SUCCESS = '[Subcategory] Save Subcategory Success',
  SAVE_SUBCATEGORY_FAILURE = '[Subcategory] Save Subcategory Failure',
  DELETE_SUBCATEGORY = '[Subcategory] Delete Subcategory',
  DELETE_SUBCATEGORY_SUCCESS = '[Subcategory] Delete Subcategory Success',
  DELETE_SUBCATEGORY_FAILURE = '[Subcategory] Delete Subcategory Failure',
  SET_SELECTED_CATEGORY = '[Category] Set selected Category',
  SET_SIDEBAR_CATEGORY = '[Category] Set sidebar Category',
}

export class SyncCategoriesAction implements Action {
  readonly type = ActionTypes.SYNC_CATEGORIES;
}

export class SyncCategoriesSuccessAction implements Action {
  readonly type = ActionTypes.SYNC_CATEGORIES_SUCCESS;
}

export class SyncCategoriesFailureAction implements Action {
  readonly type = ActionTypes.SYNC_CATEGORIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCategoriesAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES;
}

export class LoadCategoriesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_SUCCESS;
  constructor(public payload: { categories: Category[] }) {}
}

export class LoadCategoriesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CATEGORIES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteCategoryAction implements Action {
  readonly type = ActionTypes.DELETE_CATEGORY;
  constructor(public id: number) {}
}

export class DeleteCategorySuccessAction implements Action {
  readonly type = ActionTypes.DELETE_CATEGORY_SUCCESS;
}

export class DeleteCategoryFailureAction implements Action {
  readonly type = ActionTypes.DELETE_CATEGORY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SaveCategoryAction implements Action {
  readonly type = ActionTypes.SAVE_CATEGORY;
  constructor(public category: Category) {}
}

export class SaveCategorySuccessAction implements Action {
  readonly type = ActionTypes.SAVE_CATEGORY_SUCCESS;
}

export class SaveCategoryFailureAction implements Action {
  readonly type = ActionTypes.SAVE_CATEGORY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSubcategoryAction implements Action {
  readonly type = ActionTypes.DELETE_SUBCATEGORY;
  constructor(public id: number) {}
}

export class DeleteSubcategorySuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUBCATEGORY_SUCCESS;
}

export class DeleteSubcategoryFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SUBCATEGORY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SetSelectedCategoryAction implements Action {
  readonly type = ActionTypes.SET_SELECTED_CATEGORY;
  constructor(public selectedCategory: string) {}
}

export class SetSidebarCategoryAction implements Action {
  readonly type = ActionTypes.SET_SIDEBAR_CATEGORY;
  constructor(public sidebarCategory: string) {}
}

export type Actions =
  | LoadCategoriesAction
  | LoadCategoriesSuccessAction
  | LoadCategoriesFailureAction
  | SaveCategoryAction
  | SaveCategorySuccessAction
  | SaveCategoryFailureAction
  | DeleteCategoryAction
  | DeleteCategorySuccessAction
  | DeleteCategoryFailureAction
  | DeleteSubcategoryAction
  | DeleteSubcategorySuccessAction
  | DeleteSubcategoryFailureAction
  | SetSelectedCategoryAction
  | SetSidebarCategoryAction
  | SyncCategoriesAction
  | SyncCategoriesSuccessAction
  | SyncCategoriesFailureAction;
