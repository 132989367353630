<div
  class="flex flex-col w-full relative"
  [ngClass]="{ 'blue-mode': blueMode }"
>
  <div
    *ngIf="title"
    class="control-title text-[11px] font-brand-regular text-brand-blue-400 uppercase"
  >
    {{ title | translate }}
  </div>
  <div class="input-container">
    <input
      class="textbox font-brand-regular text-lg"
      [ngClass]="invalid ? 'textbox-invalid' : 'textbox'"
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (blur)="changeText($event)"
      (keyup)="onValueChanged($event)"
      [disabled]="disabled"
      [maxlength]="maxLength"
    />
    <img
      *ngIf="hasValue"
      (click)="onResetClicked()"
      src="./assets/img/icons/close-gray.svg"
      class="reset-icon reset-regular"
    />

    <img
      *ngIf="hasValue"
      (click)="onResetClicked()"
      src="./assets/img/icons/close.svg"
      class="reset-icon reset-blue"
    />

    <img
      *ngIf="showSearchIcon && !hasValue"
      (click)="onResetClicked()"
      src="./assets/icons/Icon_Search_Weiss.svg"
      class="search-icon"
    />
  </div>
</div>
