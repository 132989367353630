import { Injectable } from '@angular/core';
import { Modes } from '../constants/modes';

@Injectable({
  providedIn: 'root',
})
export class ModeService {
  public getModeLabel(mode: string): string {
    let res = '';
    switch (mode) {
      case Modes.QueryStrings.EDIT:
        res = 'modes.edit';
        break;
      case Modes.QueryStrings.APPROVER:
        res = 'modes.approver';
        break;
      case Modes.QueryStrings.VIEWER:
        res = 'modes.viewer';
        break;

      default:
        res = 'Undefined';
        break;
    }
    return res;
  }
}
