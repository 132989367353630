<div
  class="image-left-plotly-right-template-container top-bottom-padding-template"
>
  <div class="content-container">
    <div class="left-side">
      <div class="image-container">
        <rh-media-placeholder
          class="image-placeholder"
          [urlImage]="urlLeft"
          [urlVideo]="activatedUrlVideoLeft"
          [editMode]="editMode"
          [showZoomButton]="false"
          (zoomedChange)="onZoomedChanged($event)"
          placeholderCode="topLeft"
          (urlImageChange)="onImageUrlChange($event)"
          (urlVideoChange)="onUrlVideoTopLeftChange($event)"
        ></rh-media-placeholder>
      </div>
    </div>
    <div class="right-side">
      <rh-iframe-plotly-placeholder
        class="plotly-placeholder"
        placeholderCode="right"
        [src]="activatedPlotlyUrl"
        [showZoomButton]="true"
        [editMode]="editMode"
        (urlChange)="onPlotlyUrlChange($event)"
        (zoomedChange)="onZoomedChanged($event)"
      ></rh-iframe-plotly-placeholder>
    </div>
  </div>
  <div *ngIf="zoomed" class="zoom-backdrop"></div>
</div>
