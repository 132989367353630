import { Action } from '@ngrx/store';
import { IPermissionModel } from '../../../core/models/permission.model';

export enum ActionTypes {
  LOAD_PERMISSIONS = '[Permissions] Load Permissions',
  LOAD_PERMISSIONS_SUCCESS = '[Permissions] Load Permissions Success',
  LOAD_PERMISSIONS_FAILURE = '[Permissions] Load Permissions Failure',
}

export class LoadPermissionsAction implements Action {
  readonly type = ActionTypes.LOAD_PERMISSIONS;
}

export class LoadPermissionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PERMISSIONS_SUCCESS;
  constructor(public payload: { permissions: IPermissionModel }) {}
}

export class LoadPermissionsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PERMISSIONS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | LoadPermissionsAction
  | LoadPermissionsSuccessAction
  | LoadPermissionsFailureAction;
