import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from '../button/core/button-type.enum';

@Component({
  selector: 'rh-approval-controls',
  templateUrl: './approval-controls.component.html',
  styleUrls: ['./approval-controls.component.scss'],
})
export class ApprovalControlsComponent {
  @Input()
  public previewMode = false;

  @Input()
  public publishMode = false;

  public buttonTypes = ButtonType;

  @Output()
  public approvalRequested: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public rejectionRequested: EventEmitter<void> = new EventEmitter<void>();

  public async requestApproval() {
    this.approvalRequested?.emit();
  }

  public async requestRejection() {
    this.rejectionRequested?.emit();
  }
}
