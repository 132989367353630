import { Action } from '@ngrx/store';

export enum ActionTypes {
  SET_MODE = '[Auth] Set Mode',
  SET_EDIT_MODE = '[Auth] Toggle Edit Mode',
  SET_EDIT_MODE_ON = '[Auth] Set Edit Mode On',
  SET_EDIT_MODE_OFF = '[Auth] Set Edit Mode Off',
  SET_APPROVER_MODE_ON = '[Auth] Set Approver On',
  SET_APPROVER_MODE_OFF = '[Auth] Set Approver Mode Off',
  SET_PREVIEW_MODE_ON = '[Auth] Set Preview Mode On',
  SET_PREVIEW_MODE_OFF = '[Auth] Set Preview Mode Off',
  TOGGLE_PREVIEW_MODE = '[Auth] Toggle Preview Mode',
  LOGOUT_USER = '[Auth] Logout User',
}

export class ToggleEditModeAction implements Action {
  readonly type = ActionTypes.SET_EDIT_MODE;
}

export class SetModeAction implements Action {
  readonly type = ActionTypes.SET_MODE;
  constructor(public payload: { mode: string }) {}
}

export class LogOutUserAction implements Action {
  readonly type = ActionTypes.LOGOUT_USER;
}

export class SetPreviewModeOnAction implements Action {
  readonly type = ActionTypes.SET_PREVIEW_MODE_ON;
}

export class SetPreviewModeOffAction implements Action {
  readonly type = ActionTypes.SET_PREVIEW_MODE_OFF;
}

export class TogglePreviewModeAction implements Action {
  readonly type = ActionTypes.TOGGLE_PREVIEW_MODE;
}

export type Actions =
  | ToggleEditModeAction
  | SetModeAction
  | SetPreviewModeOnAction
  | SetPreviewModeOffAction
  | TogglePreviewModeAction
  | LogOutUserAction;
