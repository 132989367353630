import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { Modes } from './core/constants/modes';
import { IPermissionModel } from './core/models/permission.model';
import { ModeService } from './core/services/mode.service';
import { User } from './shared/models/user.model';
import { SetModeAction } from './shared/store/auth/actions';
import { State } from './shared/store/state';

@Component({
  selector: 'rh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  screenWidth = 0;
  sidebarOpen = false;
  verticalMenuOpen = false;
  userMenuOpen = false;
  isLoading = true;
  user$?: Observable<User | undefined>;
  user?: User | undefined;
  mode$?: Observable<string>;
  mode = '';
  adminMode = false;
  headerTitle = '';
  normalHeader = true;
  showVerticalMenu = true;
  permissions$?: Observable<IPermissionModel | undefined>;
  permissions: IPermissionModel | undefined;
  errorPermissions$?: Observable<string | undefined>;
  error: string | undefined;
  subscriptions: Subscription = new Subscription();
  currentUrl = '';

  constructor(
    private store$: Store<State>,
    private router: Router,
    private modeService: ModeService
  ) {
    this.onResize();
  }

  async ngOnInit(): Promise<void> {
    this.permissions$ = this.store$.select((state) => {
      return state.Permissions?.permissions;
    });

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;

          if (event.url.includes('edit')) {
            this.store$.dispatch(
              new SetModeAction({ mode: Modes.QueryStrings.EDIT })
            );
          }

          this.showVerticalMenu =
            event.url == '/' || event.url.includes('course-list');
          this.adminMode = event.url.includes('admin');
          console.log(this.currentUrl);
        }
      })
    );

    this.subscriptions.add(
      this.permissions$.subscribe(
        (permissions: IPermissionModel | undefined) => {
          this.permissions = permissions;
        }
      )
    );

    this.errorPermissions$ = this.store$.select((state) => {
      return state.Permissions?.error;
    });

    this.subscriptions.add(
      this.errorPermissions$.subscribe((error: string | undefined) => {
        if (error) {
          this.error = error;
        }
      })
    );

    this.user$ = this.store$.select((state) => {
      return state.User.user;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user) => {
        if (user) {
          this.user = user;
          this.isLoading = false;
        }
      })
    );

    this.mode$ = this.store$.select((state) => {
      return state.Auth.mode;
    });

    this.subscriptions.add(
      this.mode$.subscribe((mode: string) => {
        this.mode = mode;
        this.headerTitle = this.modeService.getModeLabel(this.mode);
      })
    );
  }

  logoClickedEvent() {
    console.log('logoClickedEvent', this.currentUrl);

    if (
      this.currentUrl.includes('/course/') ||
      this.currentUrl.includes('/admin')
    ) {
      this.router.navigate(['course-list']);
    } else if (this.currentUrl.includes('/course-history')) {
      this.router.navigate(
        ['/', 'course', this.currentUrl.split('/')[2].split('?')[0], 'edit'],
        {
          queryParams: {
            versionNumber: this.currentUrl.split('versionNumber=')[1],
          },
        }
      );
    } else {
      window.location.href = environment.applicationUrl;
    }
  }

  toggleSidebarEvent(): void {
    this.sidebarOpen = !this.sidebarOpen;
  }

  toggleVerticalMenuEvent(): void {
    this.verticalMenuOpen = !this.verticalMenuOpen;
  }

  toggleUserMenuEvent(): void {
    this.userMenuOpen = !this.userMenuOpen;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
