import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rh-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<TData extends { name: string }> {
  public listVisible = false;

  @Input()
  public model?: TData;

  @Input()
  public controlTitle?: string;

  private _items?: TData[];
  @Input()
  public get items(): TData[] | undefined {
    return this._items;
  }
  public set items(v: TData[] | undefined) {
    this._items = v;
  }

  @Input()
  public disabled = false;

  @Input()
  public invalidIfEmpty = true;

  @Input()
  public blueMode = false;

  @Output()
  public modelChange: EventEmitter<TData> = new EventEmitter<TData>();

  public test() {
    if (this.model && 'name' in this.model) {
      console.log(this.model.name);
    }
  }

  public async toggle() {
    this.listVisible = !this.listVisible;
  }

  public async select(item: TData) {
    this.listVisible = false;

    if (this.model === item) {
      return;
    }

    this.model = item;
    this.modelChange?.emit(item);
  }

  isSelected(item: TData): boolean {
    return this.model === item;
  }

  public onFocusOutEvent() {
    // We need to give the click-event precedence, otherwise it will not be fired
    setTimeout(() => {
      this.listVisible = false;
    }, 200);
  }
}
