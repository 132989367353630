import { OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';
import { ISlideModel } from '../../models/slide.model';
import { DialogRef } from './dialog-ref';

export class SlidesDialogRef extends DialogRef {
  public assetsReady: BehaviorSubject<ISlideModel> =
    new BehaviorSubject<ISlideModel>({ id: 0 } as ISlideModel);

  constructor(overlayRef: OverlayRef) {
    super(overlayRef);
  }

  public pushData(updatedSlide: ISlideModel) {
    this.assetsReady.next(updatedSlide);
  }
}
