<div class="confirm-cancel-dialog">
  <rh-confirm-cancel-header
    [title]="title"
    [text]="text"
  ></rh-confirm-cancel-header>
  <rh-confirm-cancel-comment-field
    *ngIf="requireComment"
    [(text)]="comment"
  ></rh-confirm-cancel-comment-field>
  <rh-confirm-cancel-toolbar
    [confirmationButtonText]="confirmationButtonText"
    [confirmButtonEnabled]="confirmButtonEnabled"
    (confirmChange)="handleEvent($event)"
  ></rh-confirm-cancel-toolbar>
</div>
