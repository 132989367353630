import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { CategoryService } from '../../services/category/category.service';
import {
  ActionTypes,
  DeleteCategoryAction,
  DeleteCategoryFailureAction,
  DeleteCategorySuccessAction,
  DeleteSubcategoryAction,
  DeleteSubcategoryFailureAction,
  DeleteSubcategorySuccessAction,
  LoadCategoriesAction,
  LoadCategoriesFailureAction,
  LoadCategoriesSuccessAction,
  SaveCategoryAction,
  SaveCategoryFailureAction,
  SaveCategorySuccessAction,
  SyncCategoriesAction,
  SyncCategoriesFailureAction,
  SyncCategoriesSuccessAction,
} from './actions';

@Injectable()
export class CategoryStoreEffects {
  constructor(
    private categoryService: CategoryService,
    private actions$: Actions
  ) {}

  syncCategoriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SyncCategoriesAction>(ActionTypes.SYNC_CATEGORIES),
      exhaustMap(() =>
        this.categoryService.SyncCategories().pipe(
          map(() => new SyncCategoriesSuccessAction()),
          catchError((error) =>
            observableOf(
              new SyncCategoriesFailureAction({ error: error.error })
            )
          )
        )
      )
    )
  );

  syncCategoriesSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SyncCategoriesSuccessAction>(ActionTypes.SYNC_CATEGORIES_SUCCESS),
      map(() => new LoadCategoriesAction())
    )
  );

  saveCategoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCategoryAction>(ActionTypes.SAVE_CATEGORY),
      exhaustMap((action) =>
        this.categoryService.SaveCategory(action.category).pipe(
          map(() => new SaveCategorySuccessAction()),
          catchError((error) =>
            observableOf(new SaveCategoryFailureAction({ error: error.error }))
          )
        )
      )
    )
  );

  deleteCategoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCategoryAction>(ActionTypes.DELETE_CATEGORY),
      exhaustMap((action) =>
        this.categoryService.DeleteCategory(action.id).pipe(
          exhaustMap(() => [new DeleteCategorySuccessAction()]),
          catchError((error) =>
            observableOf(
              new DeleteCategoryFailureAction({ error: error.error })
            )
          )
        )
      )
    )
  );

  deleteSubcategoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSubcategoryAction>(ActionTypes.DELETE_SUBCATEGORY),
      exhaustMap((action) =>
        this.categoryService.DeleteSubcategory(action.id).pipe(
          exhaustMap(() => [new DeleteSubcategorySuccessAction()]),
          catchError((error) =>
            observableOf(
              new DeleteSubcategoryFailureAction({ error: error.error })
            )
          )
        )
      )
    )
  );

  saveCategorySuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCategorySuccessAction>(ActionTypes.SAVE_CATEGORY_SUCCESS),
      map(() => new LoadCategoriesAction())
    )
  );

  deleteCategorySuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCategorySuccessAction>(ActionTypes.DELETE_CATEGORY_SUCCESS),
      map(() => new LoadCategoriesAction())
    )
  );

  deleteSubcategorySuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSubcategorySuccessAction>(
        ActionTypes.DELETE_SUBCATEGORY_SUCCESS
      ),
      map(() => new LoadCategoriesAction())
    )
  );

  loadCategoriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCategoriesAction>(ActionTypes.LOAD_CATEGORIES),
      exhaustMap(() =>
        this.categoryService.GetCategories().pipe(
          map(
            (items) =>
              new LoadCategoriesSuccessAction({
                categories: items,
              })
          ),
          catchError((error) =>
            observableOf(
              new LoadCategoriesFailureAction({ error: error.error })
            )
          )
        )
      )
    )
  );
}
