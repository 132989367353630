import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageDropdownMobileTabletComponent } from './language-dropdown-mobile-tablet.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LanguageDropdownMobileTabletComponent],
  exports: [LanguageDropdownMobileTabletComponent],
})
export class LanguageDropdownMobileTabletModule {}
