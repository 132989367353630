import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService extends ErrorHandler {
  constructor(private loggingService: LoggingService) {
    super();
  }

  override handleError(error: Error) {
    // Make sure we still see the errors in the console. 
    // We might want to switch it off conditionally depending on the deployment environment!
    console.error(error);
    this.loggingService.logException(error);
  }
}
