import { Component, Input } from '@angular/core';
import { IDynamicComponent } from '../../../../../core/interfaces/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

@Component({
  selector: 'rh-image-right-template',
  templateUrl: './image-right-template.component.html',
  styleUrls: ['./image-right-template.component.scss'],
})
export class ImageRightTemplateComponent
  extends TemplateBaseComponent
  implements IDynamicComponent
{
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T5.svg';

  public static override templateId = 5;

  @Input() public title = '';
  @Input() public html = '';
  @Input() public urlImage = '';
  @Input() public urlVideo = '';
  @Input() public footnote = '';

  public activatedUrlVideo = '';

  private htmlTemp = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL) {
        this.urlImage = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO) {
        this.urlVideo = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.HTML) {
        this.html = asset.content;
        this.htmlTemp = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE) {
        this.footnote = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedUrlVideo = this.urlVideo;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
  }

  public override onEditModeChanging(): void {
    this.html = this.htmlTemp;
  }

  public onTitleChange(title: string) {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onContentChange(content: string) {
    this.htmlTemp = content;
    this.handleAssetChange(this.htmlTemp, PlaceholderCodes.HTML);
  }

  public onUrlImageChange(url: string) {
    this.urlImage = url;
    this.handleAssetChange(this.urlImage, PlaceholderCodes.BlobBased.URL);
  }

  public onUrlVideoChange(url: string) {
    this.activatedUrlVideo = url;
    this.handleAssetChange(
      this.activatedUrlVideo,
      PlaceholderCodes.BlobBased.URL_VIDEO,
    );
  }

  public onFootnoteChange(footnote: string) {
    this.footnote = footnote;
    this.handleAssetChange(this.footnote, PlaceholderCodes.FOOT_NOTE);
  }
}
