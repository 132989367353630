import { IPermissionModel } from '../../../core/models/permission.model';

export interface State {
  permissions?: IPermissionModel;
  error?: string;
}

export const initialState: State = {
  permissions: undefined,
};
