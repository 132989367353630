import { Type } from '@angular/core';

import { CoverPageTemplateComponent } from '../../features/course-list/components/templates/cover-page-template/cover-page-template.component';
import { FourImagesRightTemplateComponent } from '../../features/course-list/components/templates/four-images-right-template/four-images-right-template.component';
import { FullWidthTextTemplateComponent } from '../../features/course-list/components/templates/full-width-text-template/full-width-text-template.component';
import { GroupHeadingsTemplateComponent } from '../../features/course-list/components/templates/group-headings-template/group-headings-template.component';
import { ImageFullWidthTemplateComponent } from '../../features/course-list/components/templates/image-full-width-template/image-full-width-template.component';
import { ImageFullscreenTemplateComponent } from '../../features/course-list/components/templates/image-fullscreen-template/image-fullscreen-template.component';
import { ImageLeftPlotlyRightComponent } from '../../features/course-list/components/templates/image-left-plotly-right/image-left-plotly-right.component';
import { ImageLeftRightTemplateComponent } from '../../features/course-list/components/templates/image-left-right-template/image-left-right-template.component';
import { ImageRightTemplateComponent } from '../../features/course-list/components/templates/image-right-template/image-right-template.component';
import { PlotlyRightTemplateComponent } from '../../features/course-list/components/templates/plotly-right-template/plotly-right-template.component';
import { TextLeftImageRightTemplateComponent } from '../../features/course-list/components/templates/text-left-image-right-template/text-left-image-right-template.component';
import { TitlePageTemplateComponent } from '../../features/course-list/components/templates/title-page-template/title-page-template.component';
import { TwoImagesRightTemplateComponent } from '../../features/course-list/components/templates/two-images-right-template/two-images-right-template.component';
import { IDynamicComponent } from '../interfaces/dynamic-component.interface';
import { ISlideModel } from '../models/slide.model';

export class TemplateTypes {
  public static activeTemplateTypes: ISlideModel[] = [
    {
      templateId: TitlePageTemplateComponent.templateId,
      templateImageUrl: TitlePageTemplateComponent.templateThumbnailUrl,
      template: TitlePageTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: GroupHeadingsTemplateComponent.templateId,
      templateImageUrl: GroupHeadingsTemplateComponent.templateThumbnailUrl,
      template: GroupHeadingsTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: CoverPageTemplateComponent.templateId,
      templateImageUrl: CoverPageTemplateComponent.templateThumbnailUrl,
      template: CoverPageTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: ImageFullWidthTemplateComponent.templateId,
      templateImageUrl: ImageFullWidthTemplateComponent.templateThumbnailUrl,
      template: ImageFullWidthTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: ImageLeftRightTemplateComponent.templateId,
      templateImageUrl: ImageLeftRightTemplateComponent.templateThumbnailUrl,
      template: ImageLeftRightTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: TextLeftImageRightTemplateComponent.templateId,
      templateImageUrl:
        TextLeftImageRightTemplateComponent.templateThumbnailUrl,
      template: TextLeftImageRightTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: ImageRightTemplateComponent.templateId,
      templateImageUrl: ImageRightTemplateComponent.templateThumbnailUrl,
      template: ImageRightTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: FullWidthTextTemplateComponent.templateId,
      templateImageUrl: FullWidthTextTemplateComponent.templateThumbnailUrl,
      template: FullWidthTextTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: TwoImagesRightTemplateComponent.templateId,
      templateImageUrl: TwoImagesRightTemplateComponent.templateThumbnailUrl,
      template: TwoImagesRightTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: FourImagesRightTemplateComponent.templateId,
      templateImageUrl: FourImagesRightTemplateComponent.templateThumbnailUrl,
      template: FourImagesRightTemplateComponent,
    } as unknown as ISlideModel,
    {
      templateId: ImageLeftPlotlyRightComponent.templateId,
      templateImageUrl: ImageLeftPlotlyRightComponent.templateThumbnailUrl,
      template: ImageLeftPlotlyRightComponent,
    } as unknown as ISlideModel,
    {
      templateId: ImageFullscreenTemplateComponent.templateId,
      templateImageUrl: ImageFullscreenTemplateComponent.templateThumbnailUrl,
      template: ImageFullscreenTemplateComponent,
    } as unknown as ISlideModel,
  ];

  public static getDynamicComponentType(
    typeName: string
  ): Type<IDynamicComponent> {
    return TemplateTypes.getTemplateType(
      typeName
    ) as unknown as Type<IDynamicComponent>;
  }

  public static getTemplateType(typeName: string): Type<IDynamicComponent> {
    let t: Type<IDynamicComponent>;
    switch (typeName) {
      case 'CoverPageTemplateComponent':
        t = CoverPageTemplateComponent;
        break;
      case 'ImageFullWidthTemplateComponent':
        t = ImageFullWidthTemplateComponent;
        break;
      case 'ImageLeftPlotlyRightComponent':
        t = ImageLeftPlotlyRightComponent;
        break;
      case 'ImageLeftRightTemplateComponent':
        t = ImageLeftRightTemplateComponent;
        break;
      case 'ImageRightTemplateComponent':
        t = ImageRightTemplateComponent;
        break;
      case 'PlotlyRightTemplateComponent':
        t = PlotlyRightTemplateComponent;
        break;
      case 'FullWidthTextTemplateComponent':
        t = FullWidthTextTemplateComponent;
        break;
      case 'TextLeftImageRightTemplateComponent':
        t = TextLeftImageRightTemplateComponent;
        break;
      case 'TitlePageTemplateComponent':
        t = TitlePageTemplateComponent;
        break;
      case 'GroupHeadingsTemplateComponent':
        t = GroupHeadingsTemplateComponent;
        break;
      case 'TwoImagesRightTemplateComponent':
        t = TwoImagesRightTemplateComponent;
        break;
      case 'FourImagesRightTemplateComponent':
        t = FourImagesRightTemplateComponent;
        break;
      case 'ImageFullscreenTemplateComponent':
        t = ImageFullscreenTemplateComponent;
        break;

      default:
        throw new Error(`'${typeName}' is not a valid template name.`);
    }

    return t;
  }

  public static getTemplateThumbnailUrl(typeName: string): string {
    let url: string;
    switch (typeName) {
      case 'CoverPageTemplateComponent':
        url = CoverPageTemplateComponent.templateThumbnailUrl;
        break;
      case 'ImageFullWidthTemplateComponent':
        url = ImageFullWidthTemplateComponent.templateThumbnailUrl;
        break;
      case 'ImageLeftPlotlyRightComponent':
        url = ImageLeftPlotlyRightComponent.templateThumbnailUrl;
        break;
      case 'ImageLeftRightTemplateComponent':
        url = ImageLeftRightTemplateComponent.templateThumbnailUrl;
        break;
      case 'ImageRightTemplateComponent':
        url = ImageRightTemplateComponent.templateThumbnailUrl;
        break;
      case 'PlotlyRightTemplateComponent':
        url = PlotlyRightTemplateComponent.templateThumbnailUrl;
        break;
      case 'FullWidthTextTemplateComponent':
        url = FullWidthTextTemplateComponent.templateThumbnailUrl;
        break;
      case 'TextLeftImageRightTemplateComponent':
        url = TextLeftImageRightTemplateComponent.templateThumbnailUrl;
        break;
      case 'TitlePageTemplateComponent':
        url = TitlePageTemplateComponent.templateThumbnailUrl;
        break;
      case 'GroupHeadingsTemplateComponent':
        url = GroupHeadingsTemplateComponent.templateThumbnailUrl;
        break;
      case 'TwoImagesRightTemplateComponent':
        url = TwoImagesRightTemplateComponent.templateThumbnailUrl;
        break;
      case 'FourImagesRightTemplateComponent':
        url = FourImagesRightTemplateComponent.templateThumbnailUrl;
        break;
      case 'ImageFullscreenTemplateComponent':
        url = ImageFullscreenTemplateComponent.templateThumbnailUrl;
        break;
      default:
        throw new Error(`'${typeName}' is not a valid template name.`);
    }

    return url;
  }
}
