import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TemplateTypes } from '../../../core/definitions/template-types.definition';
import { ICourseVersionModel } from '../../../core/models/course-version.model';
import { ICourseModel } from '../../../core/models/course.model';
import { ISlideModel } from '../../../core/models/slide.model';
import { ICourseTile } from '../../../features/course-list/components/course-tile/course-tile.model';
import { Course } from '../../models/course.model';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private http: HttpClient) {}

  SaveCourse(course: ICourseTile): Observable<{ id?: number }> {
    return this.http
      .post<{ id?: number }>(
        `${environment.backendApiUrl}/SaveCourse?id=${course.id}&code=${environment.apiKey}`,
        { course: course }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  CopyCourse(course: Course): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/DuplicateCourse?code=${environment.apiKey}`,
        { course: course }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  CreateNewCourseVersion(course: Course): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/CreateCourseVersion?code=${environment.apiKey}`,
        { course: course }
      )
      .pipe(
        map((response) => {
          console.log('new version response', response);

          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  DeleteCourseVersion(courseVersionId: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteCourseVersion?id=${courseVersionId}&code=${environment.apiKey}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  RequestApproval(
    courseVersionId: number,
    courseId: number,
    versionNumber: number
  ): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/RequestApproval?code=${environment.apiKey}`,
        {
          courseVersionId: courseVersionId,
          courseId: courseId,
          versionNumber: versionNumber,
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  CancelApproval(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/CancelApproval/${courseVersionId}?code=${environment.apiKey}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  ApproveRequest(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/ApproveRequest?code=${environment.apiKey}`,
        {
          courseVersionId: courseVersionId,
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  Unapprove(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/Unapprove/${courseVersionId}?code=${environment.apiKey}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  RejectApproval(
    courseVersionId: number,
    rejectionComment: string
  ): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/RejectApprovalRequest?code=${environment.apiKey}`,
        {
          courseVersionId: courseVersionId,
          rejectionComment: rejectionComment,
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  Publish(courseVersionId: number): Observable<object> {
    return this.http
      .post(`${environment.backendApiUrl}/Publish?code=${environment.apiKey}`, {
        courseVersionId: courseVersionId,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  Unpublish(courseVersionId: number): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/UnpublishCourseVersion?code=${environment.apiKey}`,
        {
          courseVersionId: courseVersionId,
        }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  DeleteCourse(id: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteCourse?id=${id}&code=${environment.apiKey}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  GetCoursesByUser(mode: string): Observable<ICourseTile[]> {
    return this.http
      .get<ICourseTile[]>(
        `${environment.backendApiUrl}/GetCoursesByUser?code=${environment.apiKey}&mode=${mode}`
      )
      .pipe(
        map((response) => response as ICourseTile[]),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  GetCourseById(id: number, versionNumber: number): Observable<ICourseModel> {
    return this.http
      .get<ICourseModel>(
        `${environment.backendApiUrl}/GetCourseById?id=${id}&versionNumber=${versionNumber}&code=${environment.apiKey}`
      )
      .pipe(
        map((response) => {
          const list = [] as ISlideModel[];

          for (let i = 0; i < response.slides.length; i++) {
            const element = response.slides[i];

            const item = element as unknown as ISlideModel;

            if (element.templateName) {
              item.template = TemplateTypes.getDynamicComponentType(
                element.templateName
              );
              item.templateImageUrl = TemplateTypes.getTemplateThumbnailUrl(
                element.templateName
              );
            }
            list.push(item);
          }
          return {
            id: id,
            versionNumber: response.versionNumber,
            courseVersionId: response.courseVersionId,
            approvalRequested: response.approvalRequested,
            rejected: response.rejected,
            approved: response.approved,
            published: response.published,
            slides: list,
            categoryId: response.categoryId,
            subcategoryId: response.subcategoryId,
            title: response.title,
            externalUrl: response.externalUrl,
            author: response.author,
          } as ICourseModel;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  GetCourseHistoryById(id: number): Observable<ICourseVersionModel[]> {
    return this.http
      .get<ICourseVersionModel[]>(
        `${environment.backendApiUrl}/GetCourseHistoryById?id=${id}&code=${environment.apiKey}`
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }
}
