<div class="mediapreview-modal-container">
  <div class="modal-toolbar">
    <div class="close-button" (click)="close()">
      <img class="close-icon" src="./assets/img/icons/close.svg" />
    </div>
  </div>
  <div class="preview">
    <div
      *ngIf="!isVideo"
      class="image"
      [ngStyle]="{
        'background-image': 'url(' + url + ')'
      }"
    ></div>
    <video
      *ngIf="isVideo"
      #videoElement
      [controls]="true"
      crossorigin="anonymous"
      class="video"
      preload="auto"
      [autoplay]="true"
      [loop]="true"
      type="video/mp4"
      [src]="url"
      [style.objectFit]="'cover'"
    ></video>
  </div>
</div>
