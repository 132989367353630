<div>
  <div
    *ngIf="!editMode"
    [style]="defaultTextStyle"
    class="viewer sun-editor-editable-customizations"
    [innerHtml]="html | safeHtml"
  ></div>

  <ngx-suneditor
    *ngIf="editMode"
    #editor
    [content]="html"
    [options]="editorOptions"
    (onKeyUp)="onKeyPress()"
    (onChange)="onChange($event)"
    spellcheck="false"
  ></ngx-suneditor>
</div>
