import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { ConfirmCancelDialogComponent } from '../confirm-cancel-dialog/confirm-cancel-dialog.component';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MediaLibraryModalComponent } from '../../../features/course-list/components/media-library-modal/media-library-modal.component';
import { Category } from '../../../shared/models/category.model';
import { Course } from '../../../shared/models/course.model';
import { User } from '../../../shared/models/user.model';
import {
  LogOutUserAction,
  SetModeAction,
  TogglePreviewModeAction,
} from '../../../shared/store/auth/actions';
import { SetSidebarCategoryAction } from '../../../shared/store/category/actions';
import { LoadingActiveAction } from '../../../shared/store/core/actions';
import {
  ApproveCourseAction,
  RejectCourseAction,
} from '../../../shared/store/course/actions';
import { LoadMediaEditorsAction } from '../../../shared/store/media/actions';
import { State } from '../../../shared/store/state';
import { ChangeLanguageAction } from '../../../shared/store/user/actions';
import { Modes } from '../../constants/modes';
import {
  ROLE_ADMIN_KEY,
  ROLE_APPROVER_KEY,
  ROLE_EDITOR_KEY,
} from '../../constants/roles.constants';
import { IPermissionModel } from '../../models/permission.model';
import { DialogService } from '../../services/dialog/dialog.service';
import { IConfirmCancelDialogData } from '../confirm-cancel-dialog/core/confirm-cancel-dialog-data.interface';

@Component({
  selector: 'rh-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Output() toggleSidebarEvent = new EventEmitter<void>();
  @Output() logoClicked = new EventEmitter<void>();
  @Input() sidebarOpen = false;
  @Input() public showPreviewControl = false;
  @Input() public approvalMode = false;
  @Input() public publishMode = false;
  @Input() public adminMode = false;
  @Input() public mode = '';

  language$?: Observable<string>;
  selectedLanguage = 'en';
  user$!: Observable<User>;
  user?: User;
  permissions$!: Observable<IPermissionModel>;
  permissions?: IPermissionModel;
  course$!: Observable<Course>;
  course!: Course;
  categories$?: Observable<Category[]>;
  categories?: Category[];
  mediaEditors$?: Observable<string[]>;
  mediaEditors: string[] = [];

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  public get editMode(): boolean {
    return this.mode === Modes.QueryStrings.EDIT;
  }
  public get approverMode(): boolean {
    return this.mode === Modes.QueryStrings.APPROVER;
  }

  public get viewerMode(): boolean {
    return !this.approverMode && !this.editMode;
  }

  public get isAdmin(): boolean {
    return this.checkRole(ROLE_ADMIN_KEY);
  }

  public get isEditor(): boolean {
    return this.checkRole(ROLE_EDITOR_KEY);
  }

  public get isApprover(): boolean {
    return this.checkRole(ROLE_APPROVER_KEY);
  }

  public chapterTitle = '';

  constructor(
    private store$: Store<State>,
    private translate: TranslateService,
    private router: Router,
    public dialog: DialogService,
    private responsive: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];
        })
    );

    this.store$.dispatch(new LoadMediaEditorsAction());

    this.categories$ = this.store$.select((state) => {
      return state.Category.categories;
    });

    this.subscriptions.add(
      this.categories$?.subscribe((categories: Category[]) => {
        this.categories = categories;
      })
    );

    this.mediaEditors$ = this.store$.select((state) => {
      return state.Media.editors;
    });

    this.subscriptions.add(
      this.mediaEditors$?.subscribe((editors: string[]) => {
        if (!editors || !editors.length) {
          return;
        }

        this.mediaEditors = editors;
      })
    );

    this.language$ = this.store$.select((state) => {
      return state.User.language;
    });
    this.subscriptions.add(
      this.language$?.subscribe((lang: string) => {
        this.selectedLanguage = lang;
        this.changeLanguage(lang);
      })
    );

    this.user$ = this.store$.select((state) => {
      return state.User.user as User;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user: User) => {
        this.user = user;
      })
    );

    this.permissions$ = this.store$.select((state) => {
      return state.Permissions?.permissions as IPermissionModel;
    });

    this.subscriptions.add(
      this.permissions$.subscribe((permissions) => {
        this.permissions = permissions;
      })
    );

    this.course$ = this.store$.select((state) => {
      return state.Course.currentCourse as Course;
    });

    this.subscriptions.add(
      this.course$?.subscribe((course) => {
        this.course = course;
      })
    );
  }

  checkRole(role: string): boolean {
    if (!this.permissions || !this.permissions.roles) {
      return false;
    }

    return this.permissions.roles.map((m) => m.key).indexOf(role) >= 0;
  }

  logoClick() {
    this.logoClicked.emit();
  }

  toggleSidebar() {
    this.toggleSidebarEvent.emit();
  }

  public setEditMode() {
    this.router.navigate(['/']);
    this.store$.dispatch(new SetModeAction({ mode: Modes.QueryStrings.EDIT }));
    this.store$.dispatch(new SetSidebarCategoryAction(''));
  }

  public setApproverMode() {
    this.router.navigate(['/']);
    this.store$.dispatch(
      new SetModeAction({ mode: Modes.QueryStrings.APPROVER })
    );
    this.store$.dispatch(new SetSidebarCategoryAction(''));
  }

  public setViewerMode() {
    this.router.navigate(['/']);
    this.store$.dispatch(
      new SetModeAction({ mode: Modes.QueryStrings.VIEWER })
    );
    this.store$.dispatch(new SetSidebarCategoryAction(''));
  }

  public goToAdmin() {
    this.router.navigate(['/admin']);
  }

  public togglePreviewMode() {
    this.store$.dispatch(new TogglePreviewModeAction());
  }

  public async showMediaLibraryModal() {
    const dialogRef = this.dialog.open(MediaLibraryModalComponent, {
      data: { enableSelection: false },
    });
    await lastValueFrom(dialogRef.afterClosed());
  }

  public async approve() {
    this.store$.dispatch(new LoadingActiveAction());
    this.store$.dispatch(
      new ApproveCourseAction({ courseVersionId: this.course.courseVersionId })
    );
  }

  public async reject() {
    const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
      data: {
        title: 'popups.reject.title',
        text: 'popups.reject.message',
        requireComment: true,
      } as IConfirmCancelDialogData,
    });

    const dialogResult = await lastValueFrom(dialogRef.afterClosed());

    if (!dialogResult.decision) {
      return;
    }

    this.store$.dispatch(new LoadingActiveAction());
    this.store$.dispatch(
      new RejectCourseAction({
        courseVersionId: this.course?.courseVersionId,
        rejectionComment: dialogResult.comment,
      })
    );
  }

  changeLanguage(lang: string) {
    if (!this.user) return;
    this.store$.dispatch(new ChangeLanguageAction(lang, this.user.sub));
  }

  logout() {
    this.store$.dispatch(new LogOutUserAction());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
