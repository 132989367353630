import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_APPROVERS: {
      return {
        ...state,
        isLoading: true,
        approvers: [],
        error: '',
      };
    }
    case ActionTypes.LOAD_APPROVERS_SUCCESS: {
      return {
        ...state,
        approvers: action.approvers,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_APPROVERS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_EDITORS_BY_SUB_CATEGORY: {
      return {
        ...state,
        isLoading: true,
        editors: [],
        error: '',
      };
    }
    case ActionTypes.LOAD_EDITORS_BY_SUB_CATEGORY_SUCCESS: {
      return {
        ...state,
        editors: action.editors,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_EDITORS_BY_SUB_CATEGORY_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    case ActionTypes.CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.languageKey,
      };
    }
    case ActionTypes.CHANGE_LANGUAGE_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.LOAD_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        language: action.user.ui_locales,
      };
    }
    default: {
      return state;
    }
  }
}
