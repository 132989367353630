<!-- mobile & tablet portrait -->
<div
  *ngIf="(isMobile || isTabletPortrait) && sidebarOpen"
  class="p-small fixed z-50 flex h-full w-full flex-col gap-10 bg-brand-blue-800"
>
  <div class="flex flex-row items-center justify-between">
    <button (click)="logoClick()" class="flex h-12 items-center">
      <img
        src="/assets/img/Reishauer_Icons-LogoWhite.svg"
        alt="Logo"
        class="h-10"
      />
    </button>
    <button (click)="toggleSidebar()" type="button" class="p-2">
      <span class="sr-only">Close sidebar</span>
      <img
        src="/assets/img/buttons/Reishauer_Icons-Close.svg"
        alt="Close"
        class="aspect-square h-8"
      />
    </button>
  </div>

  <div class="flex h-full flex-col gap-4 overflow-y-auto text-2xl uppercase">
    <button
      (click)="setViewerMode(); toggleSidebar()"
      class="group flex items-center gap-3 uppercase text-white"
    >
      <img
        src="/assets/img/buttons/view-mode.svg"
        alt="Viewer"
        class="aspect-auto h-10"
      />
      <span>{{ 'sidebar.viewer' | translate }}</span>
    </button>
  </div>
</div>

<!-- Desktop & tablet landscape -->
<div
  *ngIf="isWeb || isTabletLandscape"
  class="h-full inset-y-0 left-0 z-50 w-[90px] overflow-y-auto bg-brand-blue-800 pb-4"
>
  <div
    class="flex h-16 shrink-0 items-center justify-center pt-8 cursor-pointer"
    (click)="logoClick()"
  >
    <img
      class="h-[20px] w-[65px]"
      src="/assets/img/Reishauer_Icons-LogoWhite.svg"
      alt="Reishauer Logo"
    />
  </div>
  <nav class="mt-8">
    <ul role="list" class="flex flex-col items-center text-center">
      <li class="flex self-stretch cursor-pointer">
        <div
          class="group flex py-[15px] w-full flex-col justify-center items-center uppercase text-[12px] text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
          [ngClass]="
            viewerMode && !adminMode
              ? 'bg-brand-blue-550 text-white'
              : 'bg-brand-blue-800 text-brand-blue-360'
          "
          (click)="setViewerMode()"
        >
          <img
            src="/assets/img/buttons/view-mode.svg"
            alt="Viewer"
            class="w-10 h-10"
          />
          <span class="mt-2">
            {{ 'sidebar.viewer' | translate }}
          </span>
        </div>
      </li>
      <li *ngIf="isEditor" class="flex self-stretch cursor-pointer">
        <div
          class="group flex py-[15px] w-full flex-col justify-center items-center uppercase text-[12px] text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
          [ngClass]="
            editMode && !adminMode
              ? 'bg-brand-blue-550 text-white'
              : 'bg-brand-blue-800 text-brand-blue-360'
          "
          (click)="setEditMode()"
        >
          <img
            src="/assets/img/buttons/Reishauer_Mainbar_Editor.svg"
            alt="Editor"
            class="w-10 h-10"
          />
          <span class="mt-2">
            {{ 'sidebar.editor' | translate }}
          </span>
        </div>
      </li>
      <li *ngIf="isApprover" class="flex self-stretch cursor-pointer">
        <div
          class="group flex py-[15px] w-full flex-col justify-center items-center uppercase text-[12px] text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
          [ngClass]="
            approverMode && !adminMode
              ? 'bg-brand-blue-550 text-white'
              : 'bg-brand-blue-800 text-brand-blue-360'
          "
          (click)="setApproverMode()"
        >
          <img
            src="/assets/img/buttons/Reishauer_Mainbar_Approver.svg"
            alt="Approver"
            class="w-10 h-10"
          />
          <span class="mt-2">
            {{ 'sidebar.approver' | translate }}
          </span>
        </div>
      </li>
      <li *ngIf="isEditor || isAdmin" class="flex self-stretch cursor-pointer">
        <div
          class="group flex py-[15px] w-full flex-col justify-center items-center uppercase text-[12px] text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
          (click)="showMediaLibraryModal()"
        >
          <img
            src="/assets/img/Reishauer_Icon_SlidesWhite.svg"
            alt="Editor"
            class="w-10 h-10"
          />
          <span class="mt-2">
            {{ 'sidebar.mediaLibrary' | translate }}
          </span>
        </div>
      </li>
      <li class="flex self-stretch">
        <a
          *ngIf="isAdmin"
          [routerLink]="['/admin']"
          routerLinkActive="bg-brand-blue-550 text-white"
          class="group flex py-[15px] w-full flex-col justify-center items-center uppercase text-[12px] text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
        >
          <img
            src="/assets/img/Reishauer_Icon_Admin.svg"
            alt="Admin"
            class="w-10 h-10"
          />
          <span class="mt-2">
            {{ 'sidebar.admin' | translate }}
          </span>
        </a>
      </li>
    </ul>
  </nav>
</div>
