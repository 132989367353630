import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageDropdownMobileComponent } from './language-dropdown-mobile.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LanguageDropdownMobileComponent],
  exports: [LanguageDropdownMobileComponent],
})
export class LanguageDropdownMobileModule {}
