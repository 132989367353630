<div
  class="media-drag-and-drop-area-container"
  [ngClass]="{ 'drop-area-ctive': active }"
  rhDdragAndDropFiles
  (filesDropped)="onFilesDropped($event)"
  (activeChange)="onStatusChanged($event)"
>
  <span class="upload-button">
    <img
      class="img img-blue"
      (click)="selectFiles()"
      src="/assets/icons/Icon_Upload_Blau.svg"
    />
    <img
      class="img img-green"
      (click)="selectFiles()"
      src="/assets/icons/Icon_Upload_Gruen.svg"
    />
  </span>
  <span class="drag-area">Drag & Drop your files here </span>
  <span class="plus-button">
    <input
      #multiFileUpload
      class="file-input"
      type="file"
      multiple="true"
      (change)="onFilesSelected($event)"
    />
    <button class="img-button" (click)="selectFiles()">
      <img class="img" src="/assets/img/buttons/add-blue.svg" />
    </button>
  </span>
</div>

<div
  class="loading-spinner-container loading-spinner-container-background"
  *ngIf="loadingOrSaving"
>
  <rh-loading></rh-loading>
</div>
